import { GameName } from 'Components/game/gameName'
import { HBox, VBox } from 'Components/layout'
import { SubtleText } from 'Components/visual/SubtleText'
import { colors } from 'styles/colors'
import { isGameReleased } from 'util/gameLogic'
import { ReleaseDateFormatter } from '../profilePanel/releaseDateFormatter'

export function InspectPredictionBadge({ game, predictionResult }) {
  if (!predictionResult) {
    return null
  }

  return (
    <>
      <HBox
        style={{
          border: '1px solid #333',
          padding: '8px 16px 8px 16px',
          borderRadius: '8px',
        }}
        align="center"
      >
        <VBox>
          <HBox>
            <GameName game={game} />
            <HBox align="right">
              <ReleaseDateFormatter releaseDate={game.released} />
            </HBox>
          </HBox>
          <HBox>
            <div style={{ display: 'inline-block', padding: '16px 0 0 0' }}>
              <HBox>
                <VBox style={{ marginRight: '32px' }}>
                  <div
                    style={{
                      fontSize: '32px',
                      lineHeight: '1.2em',
                      color: colors.darkPurple,
                      position: 'relative',
                    }}
                  >
                    {predictionResult.predictionScore}
                    <span style={{ position: 'absolute' }}>
                      {predictionResult.predictionScore > game.metacritic && (
                        <span className="material-symbols-rounded">
                          arrow_drop_down
                        </span>
                      )}
                      {predictionResult.predictionScore < game.metacritic && (
                        <span className="material-symbols-rounded">
                          arrow_drop_up
                        </span>
                      )}
                      {predictionResult.predictionScore === game.metacritic && (
                        <span className="material-symbols-rounded">check</span>
                      )}
                    </span>
                  </div>
                  <SubtleText text="Prediction"></SubtleText>
                </VBox>
                {game.metacritic && (
                  <VBox align="left">
                    <div style={{ fontSize: '32px', lineHeight: '1.2em' }}>
                      {game.metacritic}
                    </div>
                    <SubtleText
                      text="Actual review score"
                      nowrap={true}
                    ></SubtleText>
                  </VBox>
                )}
              </HBox>
            </div>
            <VBox align="right-center">
              {predictionResult.points !== null && (
                <>
                  <div
                    style={{
                      color: colors.theme,
                      fontSize: '32px',
                      lineHeight: '1.2em',
                    }}
                  >
                    {predictionResult.points}p
                  </div>
                  <div style={{ color: `${predictionResult.grade.color}` }}>
                    {predictionResult.grade.gradeText}
                  </div>
                  {predictionResult.diff > 0 && (
                    <SubtleText
                      text={predictionResult.grade.diffText}
                    ></SubtleText>
                  )}
                </>
              )}
              {isGameReleased(game) && game.metacritic === null && (
                <>Not reviewed yet</>
              )}
              {isGameReleased(game) &&
                game.metacritic !== null &&
                predictionResult.points === null && <>No points</>}
              {!isGameReleased(game) && <>Not released yet</>}
            </VBox>
          </HBox>
        </VBox>
      </HBox>
    </>
  )
}
