import { Box, useMediaQuery } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { Cols } from 'Components/layout/cols'
import { useMemo } from 'react'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { GameCard } from './gameCard'

export const CardWideGrid = ({
  isLoading,
  list,
  limit,
  emptyText,
  readonly,
  Component,
  animateDirection,
}) => {
  const limitedList = useMemo(() => {
    if (!list) {
      console.log('Invalid list. Check out why')
      return []
    }

    return list.slice(0, limit)
  }, [list, limit])

  const isMobile = useMediaQuery(query.mobile)

  if (isLoading) {
    if (isMobile) {
      const skeletons = [...Array(5)].map((e, i) => (
        <Skeleton
          style={{
            backgroundColor: semanticSurface.surfaceLayerLow,
            minWidth: '100%',
            height: '140px',
            transform: 'scale(1)',
          }}
          key={'skeletonGrid' + i}
        />
      ))

      return <>{skeletons}</>
    } else {
      const skeletons = [...Array(!limit ? 6 : Math.min(6, limit))].map(
        (e, i) => (
          <Skeleton
            style={{
              backgroundColor: semanticSurface.surfaceLayerLow,
              minWidth: '250px',
              height: '340px',
              transform: 'scale(1)',
            }}
            key={'skeletonGrid' + i}
          />
        )
      )

      return (
        <Cols set="1fr 1fr 1fr" gap="16px" margin="16px 0">
          {skeletons}
        </Cols>
      )
    }
  }

  if (limitedList?.length === 0) {
    return <>{emptyText && <Box>{emptyText}</Box>}</>
  }

  const CardComponent = Component ?? GameCard

  /*   const variants = {
    enter: (direction) => {
      return {
        x: direction * 10,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction * 10,
        opacity: 0,
      }
    },
  } */

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(800px, 1fr))',
          gap: '16px',
          gridAutoFlow: 'dense',
        }}
      >
        {/*         <AnimatePresence initial={false} custom={animateDirection}>
         */}{' '}
        {limitedList?.map((game) => {
          return (
            <span key={game.id}>
              {/*                 <motion.div
                  custom={animateDirection}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: 'spring', stiffness: 300, damping: 15 },
                    opacity: { duration: 0.3 },
                  }}
                > */}
              {game && <CardComponent readonly={readonly} game={game} />}
              {/*                 </motion.div>
               */}{' '}
            </span>
          )
        })}
        {/*         </AnimatePresence> */}
      </div>
    </div>
  )
}
