import BronzeMedal from 'Components/panes/Bronze.svg'
import SilverMedal from 'Components/panes/Silver.svg'
import { CSTooltip } from 'Components/visual/csTooltip'
import Crown from 'Components/visual/crown.svg'
import { primitiveColors } from 'styles/primitiveColors'

export const WinnerCrown = ({ entry }) => {
  const crownStyle = {
    display: 'flex',
    transform: 'rotateZ(12deg)',
    color: 'gold',
    marginTop: '-5px',
    marginLeft: '8px',
    position: 'relative',
  }

  if (!entry.wins || entry.wins <= 0) {
    if (!entry.personalBest) {
      return null
    }

    let personalBest = entry.personalBest
    if (entry.personalBest === 3) {
      personalBest = <img src={BronzeMedal} alt="Bronze medal" />
    } else if (personalBest === 2) {
      personalBest = <img src={SilverMedal} alt="Silver medal" />
    } else {
      let pbText = personalBest + 'th'
      personalBest = <span style={{ fontSize: '10px' }}> {pbText}</span>
    }

    return (
      <span
        style={{ marginLeft: '8px', alignItems: 'center', display: 'flex' }}
      >
        {' '}
        <span style={{ fontSize: '10px' }}>Best:</span> {personalBest}
        {entry.place < entry.personalBest && (
          <span
            className="material-symbols-rounded"
            style={{ color: primitiveColors.pColorTheme500 }}
          >
            expand_less
          </span>
        )}
        {entry.place > entry.personalBest && (
          <span
            className="material-symbols-rounded"
            style={{ color: primitiveColors.pColorRed500 }}
          >
            expand_more
          </span>
        )}
      </span>
    )
  }

  return (
    <>
      <CSTooltip
        text={
          <>
            {entry.wins}-time <strong>winner</strong>
          </>
        }
      >
        <span style={crownStyle}>
          <img src={Crown} alt="crown" style={{ width: 24, height: 24 }} />
          <span
            style={{
              position: 'absolute',
              width: '23px',
              top: 0,
              color: 'black',
              fontSize: '12px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {entry.wins}
          </span>
        </span>
      </CSTooltip>
    </>
  )
}
