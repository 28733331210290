import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterWatchlist = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event, name) => {
    setGameFilter({ ...gameFilter, [name]: event.target.checked })
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={gameFilter.watchlist}
            onChange={(event) => handleChange(event, 'watchlist')}
          />
        }
        label="Show from watchlist"
      />
    </FormGroup>
  )
}
