import React, { useEffect, useMemo, useState } from 'react'
import { browserState } from 'store/browserState'
import { useGames } from 'store/game/gameContext'
import { useAuth } from 'store/user/authContext'
import {
  SCORE_UNLIKELY_THRESHOLD,
  getGamePredictionScore,
  getGamePredictions,
  isDropAfterReleaseAllowed,
  isDropAllowed,
  isGameLocked,
  isGameReleased,
  isGameReviewed,
  isGameSelected,
} from 'util/gameLogic'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import { usePredictions } from './predictionsContext'
import { useRounds } from './roundContext'

const GameFilterContext = React.createContext()

const PAGE_SIZE = 25

const GAME_SORT_OPTIONS = {
  releaseDate: 'Release',
  likelyToScore: 'Likely to score',
  score: 'Score',
  yourPrediction: 'Your prediction',
  communityPrediction: 'Community prediction',
  picks: 'Picks',
}

export const PREDICTIONS_SORT_OPTIONS = {
  release: { label: 'Release date', id: 'release' },
  points: { label: 'Points', id: 'points' },
}

function getEmptyFilter() {
  return {
    activeFilterVersion: 4,
    releaseStatus: 'UPCOMING', // 'ALL', 'UPCOMING', 'RELEASED', 'REVIEWED', 'NOT_PREDICTED', 'NOT_SCORED'
    watchlist: false,
    includeUnpopularGames: false,
    involvedCompanies: false,
    category: {
      fullGame: false,
      remake: false,
      port: false,
    },
    score: {
      platinum: false,
      gold: false,
      silver: false,
      bronze: false,
      iron: false,
    },
    platforms: {
      PS5: false,
      PS4: false,
      XSX: false,
      XONE: false,
      Switch: false,
    },
    genreVisualNovel: false,
    genreIndie: false,
  }
}

function getSavedBrowserFilter() {
  let gameFilter = browserState.getGameFilter()

  if (
    !gameFilter ||
    gameFilter.activeFilterVersion !== getEmptyFilter().activeFilterVersion
  ) {
    gameFilter = getEmptyFilter()
    browserState.saveGameFilter(gameFilter)
  }

  return gameFilter
}

function GameFilterContextProvider({ ...props }) {
  const { children } = props

  const {
    availableGames,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
    watchedGames,
  } = useGames()
  const { selectedRound, isGameReleasedInRound } = useRounds()
  const { user } = useAuth()
  const { roundGamePredictions } = usePredictions()

  // Search filter
  const [search, setSearch] = useState('')
  const [gameFilter, setGameFilterInternal] = useState(getSavedBrowserFilter())
  const [gameSortMode, setGameSortMode] = useState(
    GAME_SORT_OPTIONS.releaseDate
  )
  const [filteredGames, setFilteredGames] = useState([])
  const [page, setPage] = useState(1)

  // Predictions filters
  const [showNotReleased, setShowNotReleased] = useState(false)
  const [showNoPoints, setShowNoPoints] = useState(false)
  const [predictionsSortMode, setPredictionsSortMode] = useState(
    PREDICTIONS_SORT_OPTIONS.release
  )

  const toggleSortMode = () => {
    setPredictionsSortMode(
      predictionsSortMode.id === PREDICTIONS_SORT_OPTIONS.release.id
        ? PREDICTIONS_SORT_OPTIONS.points
        : PREDICTIONS_SORT_OPTIONS.release
    )
  }

  const clearGameFilter = () => {
    setGameFilter(getEmptyFilter())
    setSearch('')
  }

  const setGameFilter = (newFilter) => {
    setGameFilterInternal(newFilter)
    browserState.saveGameFilter(newFilter)
  }

  useEffect(() => {
    setFilteredGames(
      availableGames?.filter((game) => {
        let include = true

        // Disregard games outside of round filter
        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        switch (gameFilter.releaseStatus) {
          case 'UPCOMING':
            include = !isGameReleased(game)
            break
          case 'RELEASED':
            include = isGameReleased(game)
            break
          case 'REVIEWED':
            include = isGameReviewed(game)
            break
          case 'NOT_SCORED':
            include = isGameReleased(game) && !isGameReviewed(game)
            break
          case 'NOT_PREDICTED':
            include =
              !isGameReleased(game) &&
              game.yourPrediction === null &&
              !isGameLocked(game)
            break
          case 'ALL':
            include = true
            break
          default:
            include = false
        }

        if (include && !gameFilter.includeUnpopularGames) {
          include = game.profilePoints >= SCORE_UNLIKELY_THRESHOLD
        }

        if (include && gameFilter.watchlist) {
          include = watchedGames.find(
            (watchedGame) => watchedGame.id === game.id
          )
        }

        if (
          include &&
          (gameFilter.category.fullGame ||
            gameFilter.category.remake ||
            gameFilter.category.port)
        ) {
          include =
            (game.category === 'Full game' && gameFilter.category.fullGame) ||
            (game.category === 'Remake' && gameFilter.category.remake) ||
            (game.category === 'Port' && gameFilter.category.port)
        }

        if (
          include &&
          (gameFilter.score.platinum ||
            gameFilter.score.gold ||
            gameFilter.score.silver ||
            gameFilter.score.bronze ||
            gameFilter.score.iron)
        ) {
          include =
            (gameFilter.score.platinum && game.metacritic >= 90) ||
            (gameFilter.score.gold && game.metacritic >= 80) ||
            (gameFilter.score.silver && game.metacritic >= 70) ||
            (gameFilter.score.bronze && game.metacritic >= 60) ||
            (gameFilter.score.iron &&
              game.metacritic > 0 &&
              game.metacritic < 60)
        }

        if (
          include &&
          (gameFilter.platforms.PS5 ||
            gameFilter.platforms.PS4 ||
            gameFilter.platforms.XSX ||
            gameFilter.platforms.XONE ||
            gameFilter.platforms.Switch)
        ) {
          include =
            (gameFilter.platforms.PS5 &&
              game.platforms.find((platform) => platform.name === 'PS5')) ||
            (gameFilter.platforms.PS4 &&
              game.platforms.find((platform) => platform.name === 'PS4')) ||
            (gameFilter.platforms.XSX &&
              game.platforms.find(
                (platform) => platform.name === 'Series X'
              )) ||
            (gameFilter.platforms.XONE &&
              game.platforms.find((platform) => platform.name === 'XONE')) ||
            (gameFilter.platforms.Switch &&
              game.platforms.find((platform) => platform.name === 'Switch'))
        }

        /*   if (include && gameFilter.involvedCompanies) {
          include = game?.involved_companies?.length > 0 ?? false
        }

        if (include && gameFilter.media) {
          include = game.videos || game.screenshots || game.cover
        } */

        if (include && gameFilter.genreVisualNovel) {
          include =
            game.genres?.find(
              (genre) =>
                genre.name.toLowerCase() === 'Visual novel'.toLowerCase()
            ) === undefined ?? true
        }
        if (include && gameFilter.genreIndie) {
          include =
            game.genres?.find(
              (genre) => genre.name.toLowerCase() === 'Indie'.toLowerCase()
            ) === undefined ?? true
        }

        if (include && search.length > 0) {
          include = game.name?.toLowerCase().includes(search.toLowerCase())
        }

        return include
      })
    )
  }, [
    setFilteredGames,
    user,
    search,
    availableGames,
    watchedGames,
    selectedRound,
    gameFilter,
    isGameReleasedInRound,
  ])

  const sortedGames = useMemo(() => {
    const result =
      filteredGames?.map((game) => {
        return {
          ...game,
          profilePoints: calcGameProfilePoints(game),
          selected: isGameSelected(
            game,
            selectedGames,
            selectedGamesOutsideRound
          ),
          predictions: getGamePredictions(roundGamePredictions, game.id),
          yourPrediction: getGamePredictionScore(user, game),
          watched: watchedGames.find(
            (watchedGame) => watchedGame.id === game.id
          ),
          dropAllowed: isDropAllowed(game),
          dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
        }
      }) ?? []

    const sortedResult = result.sort((a, b) => {
      if (gameSortMode === GAME_SORT_OPTIONS.releaseDate) {
        return sortByReleasedAndName(
          a,
          b,
          gameFilter.releaseStatus !== 'ALL' &&
            gameFilter.releaseStatus !== 'UPCOMING' &&
            gameFilter.releaseStatus !== 'NOT_PREDICTED'
            ? 'DESC'
            : 'ASC'
        )
      } else if (gameSortMode === GAME_SORT_OPTIONS.likelyToScore) {
        return sortByProperty(a, b, 'profilePoints', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.picks) {
        const a1 = a?.picks?.picks?.length ?? 0
        const b1 = b?.picks?.picks?.length ?? 0
        return b1 - a1
      } else if (gameSortMode === GAME_SORT_OPTIONS.score) {
        return sortByProperty(a, b, 'metacritic', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.yourPrediction) {
        return sortByProperty(a, b, 'yourPrediction', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.communityPrediction) {
        return sortByProperty(a.predictions, b.predictions, 'average', 'DESC')
      } else {
        return sortByReleasedAndName(a, b)
      }
    })

    return sortedResult
  }, [
    selectedGames,
    selectedGamesOutsideRound,
    filteredGames,
    user,
    watchedGames,
    calcGameProfilePoints,
    gameSortMode,
    gameFilter.releaseStatus,
    roundGamePredictions,
  ])

  useEffect(() => {
    setPage(1)
  }, [gameFilter, gameSortMode])

  const filteredSelectedGames = useMemo(() => {
    return sortedGames.slice(0, page * PAGE_SIZE)
  }, [sortedGames, page])

  function addPage() {
    if (page < sortedGames.length / PAGE_SIZE) {
      setPage(page + 1)
    }
  }

  const gamesShown = useMemo(() => sortedGames.length, [sortedGames])

  const lastPage = useMemo(
    () => page >= Math.ceil(sortedGames.length / PAGE_SIZE),
    [page, sortedGames.length]
  )

  const filterGameContext = {
    filteredGames,
    setFilteredGames,
    filteredSelectedGames,
    addPage,
    lastPage,
    gamesShown,

    gameFilter,
    setGameFilter,
    clearGameFilter,

    setSearch,
    gameSortMode,
    setGameSortMode,
    GAME_SORT_OPTIONS,
    gameLimit: 10,

    showNotReleased,
    setShowNotReleased,
    showNoPoints,
    setShowNoPoints,
    predictionsSortMode,
    setPredictionsSortMode,
    toggleSortMode,
  }

  return (
    <GameFilterContext.Provider value={filterGameContext}>
      {children}
    </GameFilterContext.Provider>
  )
}

function useGameFilter() {
  const context = React.useContext(GameFilterContext)
  if (!context) {
    throw new Error(
      `useGameFilter must be used within a GameFilterContextProvider`
    )
  }
  return context
}

export { GameFilterContextProvider, useGameFilter }
