import { Alert, Box, CircularProgress } from '@mui/material'
import { CSButton } from 'Components/game/CSButton'
import { HBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Bar } from 'Components/panes/Bar'
import { Text } from 'Components/visual/text'
import { useNavigate } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { usePickGame } from 'store/game/userPickGameContext'
import { useAuth } from 'store/user/authContext'
import { fontSize } from 'styles/fontSize'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'

export const PicksSummary = ({ placedOnProfile = true }) => {
  const { isLoadingUserGames, selectedGamesOutsideRound } = useGames()
  const { pickedGameList } = usePickGame()
  const { gameLimit } = useGameFilter()
  const { user } = useAuth()
  const navigate = useNavigate()

  const progress = (pickedGameList.length / gameLimit) * 100

  if (!user.isAuthenticated) {
    return null
  }

  return (
    <ChildSpacerV
      style={{
        border: `1px solid ${semanticSurface.surfaceLayerMedium}`,
        borderRadius: 16,
        padding: 32,
      }}
    >
      <div>
        <HBox align="center">
          {isLoadingUserGames ? (
            <CircularProgress />
          ) : (
            <>
              <span
                style={{
                  fontSize: fontSize.large,
                  color: semanticMedia.contrastMedium,
                  whiteSpace: 'nowrap',
                }}
              >
                {pickedGameList.length}/{gameLimit} picked
              </span>
            </>
          )}
          <Box p={3}></Box>
          {progress > 0 && (
            <>
              <Bar progress={progress} height={18} />
              <Box p={1}></Box>
            </>
          )}
        </HBox>
        <Box p={1}></Box>
      </div>
      {pickedGameList.length < gameLimit && (
        <>
          <Text>
            You haven't picked enough games yet. Your 9 <em>best</em> games will
            score if you pick {gameLimit}.
          </Text>
          {placedOnProfile && (
            <CSButton label="Pick games" onClick={() => navigate('/games')} />
          )}
        </>
      )}
      {pickedGameList.length >= gameLimit &&
        selectedGamesOutsideRound.length === 0 && (
          <>
            <Text>
              Good job! You're all set for now. Check back later to see how your
              picks score or if there's been any changes.
            </Text>
          </>
        )}
      {selectedGamesOutsideRound.length > 0 && (
        <>
          <Alert severity="warning">
            You have {selectedGamesOutsideRound.length} picked{' '}
            {selectedGamesOutsideRound.length > 1 ? 'games' : 'game'} that's not
            part of the current round.
          </Alert>
        </>
      )}
      {!placedOnProfile && (
        <CSButton
          label="Check on your picks"
          onClick={() => navigate('/team-manage')}
        />
      )}
    </ChildSpacerV>
  )
}
