import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SurpriseIcon } from './surpriseIcon'
import { semanticMedia } from 'styles/semanticMedia'

export const GameName = ({ game }) => {
  const gameName = game?.name ?? 'Invalid name'

  return (
    <span
      style={{
        /*         display: 'block',
        position: 'relative',
 */ height: '28px',
        /* width: '100%', */
      }}
    >
      <ChildSpacerHAll style={{ alignItems: 'center' }}>
        <SurpriseIcon game={game} />
      </ChildSpacerHAll>
      <span
        style={{
          /*           position: 'absolute',
           */ whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          /* width: '100%', */
          color: semanticMedia.contrastHigh,
        }}
      >
        {gameName}
      </span>
    </span>
  )
}
