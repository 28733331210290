import { Box } from '@mui/material'
import { FilterButtonDrawer } from 'Components/interactive/filterButtonDrawer'
import { HBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { query } from 'styles/mediaQueries'
import { GameFilter } from './gameFilter'
import { GameFilterName } from './gameFilterName'
import { GameSelectionCount } from './gameFilterResultCount'

export const GameFilterControls = () => {
  const { gamesShown } = useGameFilter()
  const { isLoadingAvailableGames } = useGames()

  return (
    <>
      <Breakpoint customQuery={query.desktopLDown}>
        <ChildSpacerV>
          <GameFilterName />
          <FilterButtonDrawer
            drawerButtonLabel={'Filter'}
            anchor="right"
            ContentComponent={GameFilter}
          />
          {/*           {!isMobile && filteredSelectedGames.length > 0 && (
            <HBox align="center-right">
              <GameSortButtonGroup />
            </HBox>
          )} */}
          {!isLoadingAvailableGames && (
            <GameSelectionCount selectionCount={gamesShown} />
          )}
        </ChildSpacerV>
      </Breakpoint>
      <Breakpoint customQuery={query.desktopLUp}>
        <ChildSpacerV>
          <HBox align="center-left" flexType="fill">
            {!isLoadingAvailableGames && (
              <GameSelectionCount selectionCount={gamesShown} />
            )}
            {/*             {!isMobile && (
              <ChildSpacerH style={{ flex: '1' }}>
                {filteredSelectedGames.length > 0 && (
                  <HBox flexType="fill">
                    <Box display="flex" flex={1} />
                    <GameSortButtonGroup />
                  </HBox>
                )}
              </ChildSpacerH>
            )} */}
          </HBox>
          <Box p={1} />
        </ChildSpacerV>
      </Breakpoint>
    </>
  )
}
