import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterCategory = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event, category) => {
    setGameFilter({
      ...gameFilter,
      category: {
        ...gameFilter.category,
        [category]: event.target.checked,
      },
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Category</h4>
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.category.fullGame}
              onChange={(event) => handleChange(event, 'fullGame')}
            />
          }
          label="Full game"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.category.remake}
              onChange={(event) => handleChange(event, 'remake')}
            />
          }
          label="Remake"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.category.port}
              onChange={(event) => handleChange(event, 'port')}
            />
          }
          label="Port"
        />
      </FormGroup>
    </>
  )
}
