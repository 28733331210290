import { Button, Paper, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'
import { CardButton } from './CardButton'
import { GameCardImage } from './gameCardImage'
import { GameCardInfo } from './gameCardInfo'
import { PickDropButton } from './pickDropButton'

export const GameCard = ({ game, readonly }) => {
  const isMobile = useMediaQuery(query.mobile)
  const navigate = useNavigate()
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        border: game.selected ? `2px solid ${colors.theme}` : 'none',
        borderRadius: '8px',
        margin: game.selected ? '-1 0 0 -1' : '0',
        overflow: 'hidden',
      }}
    >
      <Button
        style={{
          display: 'flex',
          flex: 1,
          padding: 0,
          margin: 0,
          textTransform: 'none',
          textAlign: 'left',
        }}
        onClick={() => navigate('/game/' + game.slug)}
      >
        <Paper
          style={{
            display: 'flex',
            position: 'relative',
            flex: 1,
            height: isMobile ? 280 : 340,
            overflow: 'hidden',
          }}
          sx={{
            transition: '0.2s all',
            backgroundColor: semanticSurface.surfaceLayerLow,
            '&:hover': {
              backgroundColor: semanticSurface.surfaceLayerMedium,
            },

            '&:hover img': {
              opacity: 1,
              transform: 'scale(1.01)',
            },
          }}
        >
          <GameCardImage
            game={game}
            height={isMobile ? '52%' : '60%'}
            isHoverEffectActive={true}
          />
          {game.picks?.pickedPercent > 70 && (
            <span
              style={{
                color: semanticMedia.accentTheme,
                backgroundColor: semanticSurface.surfaceLayerLow,
                borderRadius: '8px',
                padding: '2px 8px',
                position: 'absolute',
                right: '16px',
                top: '16px',
              }}
            >
              Popular pick!
            </span>
          )}

          <GameCardInfo game={game} />
          {game.selected && (
            <span
              style={{
                position: 'absolute',
                left: 16,
                top: 16,
                color: semanticMedia.contrastHigh,
                backgroundColor: semanticSurface.surfaceThemeMedium,
                borderRadius: '50%',
                padding: '4px 4px',
                lineHeight: 0,
              }}
            >
              <span className="material-symbols-rounded">check</span>
            </span>
          )}
        </Paper>
      </Button>

      <PickDropButton
        style={{
          position: 'absolute',
          right: 8,
          bottom: 8,
        }}
        game={game}
        ButtonComponent={CardButton}
        readonly={readonly}
      />
    </div>
  )
}
