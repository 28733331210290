import { primitiveOpacity } from "./primitiveOpacity";

export const primitiveColors = {
  /// from design system
  /// theme (green)
  pColorTheme50: "#ECFDF5",
  pColorTheme100: "#D0FBE6",
  pColorTheme200: "#A6F4D2",
  pColorTheme300: "#6CE9BB",
  pColorTheme400: "#32D59F",
  pColorTheme500: "#0FD297",
  pColorTheme600: "#03986E",
  pColorTheme700: "#027A5B",
  pColorTheme800: "#05604A",
  pColorTheme900: "#054F3E",
  pColorTheme950: "#012D23",

  /// purple (secondary)
  pColorPurple50: "#F0F4FD",
  pColorPurple100: "#E3EAFC",
  pColorPurple200: "#CDD8F8",
  pColorPurple300: "#AFBDF2",
  pColorPurple400: "#8E9CEB",
  pColorPurple500: "#737BE1",
  pColorPurple600: "#5857D4",
  pColorPurple700: "#4B48BA",
  pColorPurple800: "#3F3E9A",
  pColorPurple900: "#373878",
  pColorPurple950: "#212046",

  /// gray (supporting)
  pColorGray0: "#FFFFFF",
  pColorGray50: "#F6F7F9",
  pColorGray100: "#ECEEF2",
  pColorGray200: "#D4D8E3",
  pColorGray300: "#AFB7CA",
  pColorGray400: "#8390AD",
  pColorGray500: "#5D6B89",
  pColorGray600: "#4F5B7A",
  pColorGray700: "#414A63",
  pColorGray800: "#384054",
  pColorGray900: "#323848",
  pColorGray950: "#212430",
  pColorGray1000: "#000000",

  /// yellow (warning)
  pColorYellow50: "#FDFAE9",
  pColorYellow100: "#FBF6C6",
  pColorYellow200: "#F9E98F",
  pColorYellow300: "#F5D74F",
  pColorYellow400: "#F0C11F",
  pColorYellow500: "#E0A912",
  pColorYellow600: "#C1820D",
  pColorYellow700: "#A9660F",
  pColorYellow800: "#804A13",
  pColorYellow900: "#6D3D16",
  pColorYellow950: "#3F1F09",

  /// red (destructive)
  pColorRed50: "#FEF2F2",
  pColorRed100: "#FEE2E2",
  pColorRed200: "#FECACA",
  pColorRed300: "#FDA4A4",
  pColorRed400: "#F97070",
  pColorRed500: "#F25A5A",
  pColorRed600: "#DE2424",
  pColorRed700: "#C41C1C",
  pColorRed800: "#9A1A1A",
  pColorRed900: "#801C1C",
  pColorRed950: "#460909",
};

export const themeOpacity = {
  pThemeOpacity4: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity4})`, // based on pColorTheme500
  pThemeOpacity8: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity8})`,
  pThemeOpacity16: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity16})`,
  pThemeOpacity24: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity24})`,
  pThemeOpacity48: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity48})`,
  pThemeOpacity64: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity64})`,
  pThemeOpacity80: `hsla(162, 87, 44, ${primitiveOpacity.pOpacity80})`,

  pBlackOpacity4: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity4})`,
  pBlackOpacity8: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity8})`,
  pBlackOpacity16: `hsla(0, 0%, 0%,${primitiveOpacity.pOpacity16})`,
  pBlackOpacity24: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity24})`,
  pBlackOpacity48: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity48})`,
  pBlackOpacity64: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity64})`,
  pBlackOpacity80: `hsla(0, 0%, 0%, ${primitiveOpacity.pOpacity80})`,

  pWhiteOpacity4: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity4})`,
  pWhiteOpacity8: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity8})`,
  pWhiteOpacity16: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity16})`,
  pWhiteOpacity24: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity24})`,
  pWhiteOpacity48: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity48})`,
  pWhiteOpacity64: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity64})`,
  pWhiteOpacity80: `hsla(255, 100%, 100%, ${primitiveOpacity.pOpacity80})`,

  pDestructiveOpacity4: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity4})`,
  pDestructiveOpacity8: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity8})`,
  pDestructiveOpacity16: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity16})`,
  pDestructiveOpacity24: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity24})`,
  pDestructiveOpacity48: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity48})`,
  pDestructiveOpacity64: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity64})`,
  pDestructiveOpacity80: `hsla(0, 74, 51, ${primitiveOpacity.pOpacity80})`,
};
