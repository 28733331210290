import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useAuth } from 'store/user/authContext'

export const GameFilterReleaseStatus = ({ gameFilter, setGameFilter }) => {
  const handleReleaseStatus = (event) => {
    setGameFilter({ ...gameFilter, releaseStatus: event.target.value })
  }

  const { user } = useAuth()

  return (
    <>
      <FormControl>
        <h4>Status</h4>
        <RadioGroup
          aria-labelledby="status-group-label"
          value={gameFilter.releaseStatus}
          name="radio-buttons-group"
          onChange={handleReleaseStatus}
        >
          <FormControlLabel value="ALL" control={<Radio />} label="All" />
          <FormControlLabel
            value="UPCOMING"
            control={<Radio />}
            label="Upcoming"
          />
          {user.isAuthenticated && (
            <FormControlLabel
              value="NOT_PREDICTED"
              control={<Radio />}
              label="Not predicted"
            />
          )}
          <FormControlLabel
            value="RELEASED"
            control={<Radio />}
            label="Released"
          />
          <FormControlLabel
            value="REVIEWED"
            control={<Radio />}
            label="Reviewed"
          />
          {user.admin && (
            <FormControlLabel
              value="NOT_SCORED"
              control={<Radio />}
              label="Not scored"
            />
          )}
        </RadioGroup>
      </FormControl>
    </>
  )
}
