import { Box } from '@mui/material'
import { VBox } from 'Components/layout'
import { Cols } from 'Components/layout/cols'
import { DarkList } from 'Components/visual/darkList'
import { HomePage } from 'pages/home/home'
import { useMemo } from 'react'
import { useGames } from 'store/game/gameContext'
import { useAuth } from 'store/user/authContext'

export const AdminPage = () => {
  const { user } = useAuth()

  const { isLoadingGameProfile, gameProfile } = useGames()

  const publishers = useMemo(() => {
    return Object.keys(gameProfile?.publishers ?? [])
      .map((key) => {
        return { name: key, points: gameProfile.publishers[key] }
      })
      .sort((a, b) => b.points - a.points)
  }, [gameProfile])

  const developers = useMemo(() => {
    return Object.keys(gameProfile?.developers ?? [])
      .map((key) => {
        return { name: key, points: gameProfile.developers[key] }
      })
      .sort((a, b) => b.points - a.points)
  }, [gameProfile])

  if (!user.isAuthenticated) {
    return <HomePage />
  }

  const columns = [
    {
      flex: 5,
      ColComponent: ({ colData }) => {
        return <VBox align="center-left">{colData.entry.name}</VBox>
      },
    },
    {
      flex: 1,
      align: 'center-right',
      ColComponent: ({ colData }) => {
        return (
          <VBox align="center-left">
            {colData.entry.points > 0
              ? '+' + colData.entry.points / 20 + ' games'
              : colData.entry.points / 20 + ' games'}
          </VBox>
        )
      },
    },
  ]

  return (
    <div style={{ padding: '40px' }}>
      <div>{user?.isAuthenticated && user?.admin && <>Welcome admin.</>}</div>
      <Box p={5} />
      <h2>Game profile</h2>
      Top publishers and developers receiving scores in showdown. Table shows
      amount of games receiving score vs not receiving score giving companies a
      track record of sorts for showdown.
      <Box p={3} />
      <Cols set="1fr 1fr" gap="32px">
        <div>
          <DarkList
            isLoading={isLoadingGameProfile}
            label="Top publishers"
            emptyText="No publishers."
            columns={columns}
            list={publishers}
          />
        </div>
        <div>
          <DarkList
            isLoading={isLoadingGameProfile}
            label="Top developers"
            emptyText="No developers."
            columns={columns}
            list={developers}
          />
        </div>
      </Cols>
    </div>
  )
}
