import * as React from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { useAuth } from 'store/user/authContext'
import useCachedData from 'util/useCachedData'

const FriendsContext = React.createContext()

function FriendsProvider({ children }) {
  const { user } = useAuth()

  const [isLoadingFriends, friendsRaw] = useCachedData(
    apiEndpoints.user_friends,
    [],
    [user.isAuthenticated],
    null,
    28800000,
    'get',
    user.isAuthenticated
  )

  const [friends, setFriends] = React.useState([])

  React.useEffect(() => {
    if (user.isAuthenticated) {
      setFriends(friendsRaw)
    } else {
      setFriends([])
    }
  }, [setFriends, friendsRaw, user.isAuthenticated])

  function onAddFriend(name, friendUserId) {
    setFriends([
      ...friends,
      { name, userId: friendUserId, status: 'REQUEST_SENT' },
    ])
  }

  function onManageFriend(friendUserId, action) {
    if (action === 'DELETE') {
      setFriends(friends.filter((friend) => friend.userId !== friendUserId))
      return
    }

    let newStatus = ''
    switch (action) {
      case 'ACCEPT':
        newStatus = 'ACCEPTED'
        break
      case 'IGNORE':
        newStatus = 'IGNORED'
        break
      default:
        console.error('Unknown action')
    }

    let updatedFriends = friends.map((friend) => {
      if (friend.userId === friendUserId) {
        return { ...friend, status: newStatus }
      }
      return friend
    })

    setFriends(updatedFriends)
  }

  const predictionsContext = {
    isLoadingFriends,
    friends,
    onAddFriend,
    onManageFriend,
  }

  return (
    <FriendsContext.Provider value={predictionsContext}>
      {children}
    </FriendsContext.Provider>
  )
}

function useFriends() {
  const context = React.useContext(FriendsContext)
  if (!context) {
    throw new Error(`useFriends must be used within a FriendsProvider`)
  }
  return context
}

export { FriendsProvider, useFriends }
