import { Button, Typography, useMediaQuery } from '@mui/material'
import { isBefore } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'
import {
  buildTodayZero,
  formatISODate,
  formatLongDate,
  isWithinTwoWeeksBefore,
} from 'util/date'
import { Bar } from './Bar'
import { primitiveColors } from 'styles/primitiveColors'
import { semanticInteractive } from 'styles/semanticInteractive'

export const RoundSwitch = () => {
  const { selectedRound, buildRoundProgressPercent } = useRounds()

  const roundProgressPercent = buildRoundProgressPercent(selectedRound)

  const navigate = useNavigate()

  const isMobile = useMediaQuery(query.mobile)

  const startMonthName = isMobile
    ? selectedRound.startMonthShortName
    : selectedRound.startMonthName
  const endMonthName = isMobile
    ? selectedRound.endMonthShortName
    : selectedRound.endMonthName

  const { mainRounds } = useRounds()
  const nextRound = mainRounds.next

  const roundType =
    selectedRound.id === mainRounds.current.id
      ? 'current'
      : isBefore(selectedRound.startDate, mainRounds.current.startDate)
      ? 'past'
      : 'future'

  return (
    <div id="timeline" style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={() => navigate('/select-round')}
        style={{
          padding: '16px',
          margin: '0 0 8px 0',
          color: colors.white,
          display: 'flex',
          flex: 1,
          gap: '8px',
          position: 'relative',
        }}
      >
        <span className="material-symbols-rounded">swap_horiz</span>{' '}
        <span style={{ fontSize: '18px' }}>{selectedRound.fullName}</span>
        {isWithinTwoWeeksBefore(nextRound.startDate, buildTodayZero()) && (
          <span
            style={{
              /* border: `1px dashed ${semanticMedia.contrastLow}`, */
              color: semanticMedia.accentTheme,
              backgroundColor: semanticSurface.surfaceLayerLow,
              borderRadius: '8px',
              padding: '2px 8px',
              position: 'absolute',
              right: '0',
              top: '0',
              transform: 'rotateZ(10deg)',
            }}
          >
            Next round available!
          </span>
        )}
      </Button>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography component="h2" variant="h2" style={{ fontSize: '14px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent:
                roundType === 'current' ? 'space-between' : 'center',
            }}
          >
            {roundType === 'current' && (
              <>
                <span>{startMonthName}</span>
                {endMonthName}
              </>
            )}
            {roundType === 'past' && (
              <>Ended {formatLongDate(formatISODate(selectedRound.endDate))}</>
            )}
            {roundType === 'future' && (
              <>
                Begins {formatLongDate(formatISODate(selectedRound.startDate))}
              </>
            )}
          </div>
        </Typography>
        <Bar
          progress={roundProgressPercent}
          color={
            roundType === 'past'
              ? primitiveColors.pColorYellow200
              : semanticInteractive.fillThemeDefault
          }
        />
      </div>
    </div>
  )
}
