import { Box, Button, useMediaQuery } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import { GameListImage } from 'Components/game/gameListImage'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { useMemo } from 'react'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { colors } from './../../styles/colors'
import { HLine } from './HLine'
import { Text } from './text'

const StyledRow = styled('div')((props) => ({}))

export const DarkList = ({
  label,
  subLabel,
  list,
  limit,
  headerButtonLabel,
  onButtonClick,
  onRowClick,
  columns,
  isLoading,
  emptyText,
  markFn,
  rowPadding,
  stackOnMobile,
}) => {
  const listData = useMemo(() => {
    if (!list) {
      console.log('Invalid list. Check out why')
      return []
    }

    return list.slice(0, limit)
  }, [list, limit])

  if (!listData) {
    console.error('No list supplied to DarkList', label)
    return null
  }

  return (
    <>
      {label && (
        <>
          <HLine
            label={label}
            buttonLabel={headerButtonLabel}
            onButtonClick={onButtonClick}
          />
        </>
      )}
      <VBox>
        {subLabel && (
          <>
            <ChildSpacerV>
              <VBox>
                {subLabel && (
                  <>
                    <Text>{subLabel}</Text>
                    <Box p={2} />
                  </>
                )}
              </VBox>
            </ChildSpacerV>
          </>
        )}
        <Box>
          {isLoading && <DarkListLoading />}
          {!isLoading && (
            <>
              {emptyText && (!listData || listData.length === 0) && (
                <DarkListEmpty label={label} emptyText={emptyText} />
              )}
              {listData?.length > 0 &&
                listData?.map((entry, rowIndex) => (
                  <DarkListRow
                    key={label + 'row' + rowIndex}
                    label={label}
                    onRowClick={onRowClick}
                    rowPadding={rowPadding}
                    rowIndex={rowIndex}
                    markFn={markFn}
                    columns={columns}
                    entry={entry}
                    stackOnMobile={stackOnMobile}
                  />
                ))}
            </>
          )}
        </Box>
      </VBox>
    </>
  )
}

export const DarkListLoading = () => {
  const skeletons = [...Array(5)].map((e, i) => (
    <Skeleton
      style={{
        backgroundColor: semanticSurface.surfaceLayerLow,
        height: '40px',
        minWidth: '30%',
      }}
      key={'skeleton' + i}
    />
  ))

  return skeletons
}

const DarkListEmpty = ({ emptyText }) => {
  return <>{emptyText}</>
}

const DarkListRow = (props) => {
  const {
    label,
    onRowClick,
    rowIndex,
    rowPadding,
    markFn,
    columns,
    entry,
    stackOnMobile,
  } = props

  const DarkListRowContent = () => {
    const isMobile = useMediaQuery(query.mobile)

    return (
      <StyledRow
        style={{
          flex: 1,
          color: 'white',
          overflow: 'hidden',
          position: 'relative',
          border:
            markFn && markFn(entry) ? `1px solid ${colors.theme}` : 'none',
        }}
      >
        <GameListImage game={entry} height={'100%'} opacity={0.1} />
        <HBox
          style={{
            flexDirection:
              isMobile && stackOnMobile !== false ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            /*             backgroundColor:
              rowIndex % 2 === 1
                ? themeOpacity.pBlackOpacity8
                : themeOpacity.pBlackOpacity16, */
            padding: rowPadding ?? '8px 0',
            flex: 1,
            /* pointerEvents: 'none', */
            borderBottom: `1px solid ${semanticSurface.surfaceLayerHigh}`,
          }}
        >
          {columns.map((colConfig, colIndex) => (
            <DarkListColumn
              key={label + 'row' + rowIndex + 'col' + colIndex}
              label={label}
              rowIndex={rowIndex}
              entry={entry}
              colConfig={colConfig}
              colIndex={colIndex}
            />
          ))}
        </HBox>
      </StyledRow>
    )
  }

  if (onRowClick) {
    return (
      <Button
        style={{ padding: 0, margin: 0 }}
        onClick={() => onRowClick(entry)}
        fullWidth
      >
        <DarkListRowContent {...props} />
      </Button>
    )
  } else {
    return <DarkListRowContent {...props} />
  }
}

const DarkListColumn = ({ entry, label, rowIndex, colIndex, colConfig }) => {
  if (typeof colConfig === 'string') {
    return (
      <Box
        flex={colIndex === 0 ? 6 : 3}
        style={{
          textAlign: colIndex === 0 ? 'left' : 'right',
          /* pointerEvents: 'none', */
          zIndex: 1,
        }}
      >
        {entry[colConfig]}
      </Box>
    )
  } else if (typeof colConfig === 'object') {
    const colData = { entry, label, rowIndex, colIndex }

    if (colConfig.isVisibleFn && !colConfig.isVisibleFn(colData)) {
      return null
    }

    return (
      <Box
        flex={colConfig.flex ? colConfig.flex : colIndex === 0 ? 6 : 3}
        style={{
          fontSize: '16px',
          textAlign: colConfig.align
            ? colConfig.align
            : colIndex === 0
            ? 'left'
            : 'right',
          width: '100%',
          /* pointerEvents: 'none', */
          zIndex: 1,
        }}
      >
        {colConfig.fieldName && entry[colConfig.fieldName]}
        {colConfig.ColComponent && <colConfig.ColComponent colData={colData} />}
      </Box>
    )
  }
}
