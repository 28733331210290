import { Box } from '@mui/material'
import { Text } from 'Components/visual/text'
import { Link } from 'react-router-dom'
import { isGameReleased } from 'util/gameLogic'
import { TeamName } from '../teamName'

export const GamePicksList = ({ game }) => {
  const list = game?.picks?.picks?.slice(0, 3) ?? []

  return (
    <>
      {isGameReleased(game) && list.length > 0 && (
        <>
          {list.map((entry, index) => {
            return (
              <>
                <Link
                  to={`/team/${entry.userId}`}
                  key={index}
                  style={{ position: 'relative', textUnderlineOffset: '8px' }}
                >
                  <TeamName name={entry.teamName} teamId={entry.teamId} />
                </Link>{' '}
              </>
            )
          })}

          <Box p={3} />
        </>
      )}
      {isGameReleased(game) && list.length === 0 && (
        <>
          <Text>No picks were made.</Text>
          <Box p={1} />
        </>
      )}
      {!isGameReleased(game) && list.length === 0 && (
        <>
          <Text>Not enough players have picked this game.</Text>
          <Box p={1} />
        </>
      )}
    </>
  )
}
