import { styled } from '@mui/material/styles'
import { spacing } from 'styles/spacing'

const StackV = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  gap: `${spacing.md} !important`,
})

export const ChildSpacerV = ({ children, style }) => {
  return <StackV style={style}>{children}</StackV>
}

const StackVAll = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    marginBottom: `${spacing.md} !important`,
  },
})

export const ChildSpacerVAll = ({ children, style }) => {
  return <StackVAll style={style}>{children}</StackVAll>
}

const StackH = styled(`div`)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  verticalAlign: 'middle',
  '& > *:not(:last-child)': {
    marginRight: `${spacing.sm} !important`,
  },
})

export const ChildSpacerH = ({ children, style }) => {
  return <StackH style={style}>{children}</StackH>
}

const StackHAll = styled(`div`)({
  display: 'flex',
  flexDirection: 'row',
  '& > *': {
    marginRight: '4px !important',
  },
})

export const ChildSpacerHAll = ({ children, style }) => {
  return <StackHAll style={style}>{children}</StackHAll>
}
