import { useAuth } from 'store/user/authContext'
import { primitiveColors } from 'styles/primitiveColors'
import { semanticMedia } from 'styles/semanticMedia'

export const TeamName = ({ name, teamId }) => {
  const { user } = useAuth()

  return (
    <span
      style={{
        color:
          teamId && teamId === user?.team?.id
            ? primitiveColors.pColorYellow500
            : semanticMedia.contrastMedium,
      }}
    >
      {name}
    </span>
  )
}
