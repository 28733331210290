import { Typography } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { CSTooltip } from 'Components/visual/csTooltip'
import Crown from 'Components/visual/crown.svg'
import { CSButton } from 'Components/game/CSButton'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useAuth } from 'store/user/authContext'
import { useUINotifs } from 'store/game/uiNotificationsContext'
import { useFriends } from 'store/game/friendsContext'
import { Text } from 'Components/visual/text'
import { HBox } from 'Components/layout'
import { useState } from 'react'
import { signOutFromFirebase } from 'Components/firebase'
import { TeamName } from 'Components/panes/teamName'

function ProfileHeaderWinnerCrown({ entry }) {
  if (!entry.picksWins || entry.picksWins <= 0) {
    return null
  }

  return (
    <CSTooltip id={'profile-crown'} text={`${entry.picksWins}-time winner`}>
      <img
        src={Crown}
        alt="crown"
        style={{
          width: 24,
          height: 24,
          transform: 'rotateZ(20deg)',
          position: 'absolute',
          color: 'gold',
        }}
      />
    </CSTooltip>
  )
}

export const UserPanelViewHeader = ({ team }) => {
  const { user } = useAuth()

  const { setGameActionSnackState } = useUINotifs()

  const { onAddFriend } = useFriends()

  const [savingFriendId, setSavingFriendId] = useState(null)

  const addFriend = async (event) => {
    const friend = {
      friendUserId: team.userId,
    }

    setSavingFriendId(team.userId)
    await apiPost(apiEndpoints.user_friend_add, friend)
      .then((data) => {
        setSavingFriendId(null)
        onAddFriend(team.name, team.userId)
        setGameActionSnackState({
          isOpen: true,
          text: `Friend request sent`,
          severity: 'success',
        })
      })
      .catch((error) => {
        setSavingFriendId(null)
        if (error.status === 403) {
          console.log('Not logged in')
          setGameActionSnackState({
            isOpen: true,
            text: `You need to log in first`,
            severity: 'info',
          })
          signOutFromFirebase()
        } else if (error) {
          console.error(error)
          setGameActionSnackState({
            isOpen: true,
            text: `Could not manage friend due to a server error`,
            severity: 'error',
          })
        }
      })
  }

  const { friends } = useFriends()

  const friendStatus =
    friends.find((friend) => friend.userId === team.userId)?.status ?? null

  return (
    <ChildSpacerVAll>
      <Typography
        variant="h2"
        style={{ textAlign: 'center', position: 'relative' }}
      >
        <TeamName name={team.name} teamId={team.id} />{' '}
        <ProfileHeaderWinnerCrown entry={team} />
      </Typography>

      {user.isAuthenticated && !friendStatus && user.id !== team.userId && (
        <CSButton
          saving={savingFriendId === team.userId}
          onClick={() => addFriend()}
          label="Add friend"
        ></CSButton>
      )}
      {user.isAuthenticated && friendStatus === 'REQUEST_SENT' && (
        <HBox align="center">
          <Text>Friend request sent</Text>
        </HBox>
      )}
      {user.isAuthenticated && friendStatus === 'ACCEPTED' && (
        <HBox align="center">
          <Text>Friend</Text>
        </HBox>
      )}
      {user.isAuthenticated && friendStatus === 'BLOCKED' && (
        <HBox align="center">
          <Text>Blocked</Text>
        </HBox>
      )}
    </ChildSpacerVAll>
  )
}
