import { Typography } from '@mui/material'
import { useGames } from 'store/game/gameContext'

export const GameSelectionCount = ({ selectionCount }) => {
  const { isLoadingAvailableGames, availableGames } = useGames()

  return (
    <>
      {!isLoadingAvailableGames && (
        <Typography style={{ marginRight: '16px' }}>
          {selectionCount} of {availableGames.length} games
        </Typography>
      )}
    </>
  )
}
