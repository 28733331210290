import { Box } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { CardWideGrid } from 'Components/game/cardWideGrid'
import { GameReviewCard } from 'Components/game/gameReviewCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { Text } from 'Components/visual/text'
import { useMemo } from 'react'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { query } from 'styles/mediaQueries'
import { isGameSelected } from 'util/gameLogic'

const listTitle = 'Latest scores'

export const ReviewedList = () => {
  return <ReviewedListRenderer />
}

const ReviewedListRenderer = () => {
  const {
    isLoadingAvailableGames,
    selectedGames,
    selectedGamesOutsideRound,
    reviewedList,
  } = useGames()

  const reviewedSelectedGames = useMemo(() => {
    const result =
      reviewedList?.map((game) => {
        return {
          ...game,
          selected: isGameSelected(
            game,
            selectedGames,
            selectedGamesOutsideRound
          ),
        }
      }) ?? []

    return result
  }, [selectedGames, selectedGamesOutsideRound, reviewedList])

  if (!isLoadingAvailableGames && reviewedList.length <= 0) {
    return (
      <>
        <HLine>{listTitle}</HLine>
        <Text>Recent reviews within the past week show up here.</Text>
        <Box p={2} />
      </>
    )
  }

  return (
    <ChildSpacerVAll>
      <PreviewDrawer
        anchor="left"
        ContentComponent={DrawerGameGrid}
        isLoading={isLoadingAvailableGames}
        reviewedList={reviewedSelectedGames}
      />
    </ChildSpacerVAll>
  )
}

function DrawerGameGrid({ limit, isLoading, reviewedList, onButtonClick }) {
  return (
    <>
      <HLine>{listTitle}</HLine>
      <Text>From the last week.</Text>

      <Breakpoint customQuery={query.mobile}>
        <CardGrid
          limit={limit}
          list={reviewedList}
          isLoading={isLoading}
          Component={GameReviewCard}
          readonly={true}
          emptyText="No games are reviewed on metacritic yet for this round."
        />
      </Breakpoint>
      <Breakpoint customQuery={query.mobileLandscapeUp}>
        <CardWideGrid
          limit={limit}
          list={reviewedList}
          isLoading={isLoading}
          Component={GameReviewCard}
          readonly={true}
          emptyText="No games are reviewed on metacritic yet for this round."
        />
      </Breakpoint>
    </>
  )
}
