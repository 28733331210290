import { Box } from "@mui/material"
import { GameFilterView } from "Components/game/gameFilterView"
import { Outlet } from "react-router-dom"
export const GamesContent = () => {
  return (
    <>
      <h2>Games</h2>
      <Box p={3}></Box>
      <Outlet />
      <GameFilterView />
      <Box p={3}></Box>
    </>
  )
}
