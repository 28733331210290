import { Typography } from '@mui/material'
import { useRounds } from 'store/game/roundContext'
import { colors } from 'styles/colors'
import { isGameMovedToFuture, isGameStale } from 'util/gameLogic'
import { CSTooltip } from './csTooltip'

function ContentComponent({ futureGame, staleGame }) {
  return (
    <Typography>
      {staleGame && (
        <>
          <strong>
            This game was released more than a week ago and has yet to receive a
            review.
          </strong>
          <br />
          We recommend replacing it before the round is over.
        </>
      )}
      {futureGame && (
        <>
          <strong>
            This game was moved into next round and can safely be dropped.
          </strong>
          <br />
          We strongly recommend replacing it before the round is over.
        </>
      )}
    </Typography>
  )
}

export const GameStaleWarning = ({ game, vAlign, enableFutureGameWarning }) => {
  const { mainRounds, selectedRound } = useRounds()

  if (mainRounds.current.id !== selectedRound.id) {
    return null
  }

  const staleGame = isGameStale(game)
  const futureGame = isGameMovedToFuture(game, mainRounds.current)

  if (!staleGame && (!enableFutureGameWarning || !futureGame)) {
    return null
  }

  return (
    <CSTooltip
      id={game.id}
      text={<ContentComponent staleGame={staleGame} futureGame={futureGame} />}
    >
      <span
        className="material-symbols-rounded"
        style={{
          fontSize: '18px',
          verticalAlign: vAlign ? vAlign : 'middle',
          color: colors.pink,
        }}
      >
        release_alert
      </span>
    </CSTooltip>
  )
}
