import { HBox } from 'Components/layout'
import { DarkList } from 'Components/visual/darkList'
import { Text } from 'Components/visual/text'
import { WinnerCrown } from 'pages/home/profilePanel/WinnerCrown'
import { useMemo } from 'react'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { primitiveColors } from 'styles/primitiveColors'
import { spacing } from 'styles/spacing'
import {
  getGamePredictionScore,
  getGamePredictions,
  isGameLocked,
  isGameReleased,
} from 'util/gameLogic'
import { sortByProperty } from 'util/sort'
import { TeamName } from '../teamName'
import { Box } from '@mui/material'

const scoring = {
  0: 15,
  1: 10,
  2: 7,
  3: 5,
  4: 2,
  5: 1,
}

export const GamePredictionsList = ({ game, maxItems = 3 }) => {
  const { user } = useAuth()

  const userPrediction = getGamePredictionScore(user, game)

  const { isLoadingRoundGamePredictions, roundGamePredictions } =
    usePredictions()

  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, game.id)
  }, [roundGamePredictions, game])

  const sortedPredictions = useMemo(() => {
    return (
      gamePredictions?.teams
        .filter(
          (team) =>
            team.score > 0 && (userPrediction > 0 || isGameReleased(game))
        )
        .map((team) => {
          const distance =
            game.metacritic > 0
              ? Math.abs(team.score - game.metacritic)
              : team.score

          let points = 0
          if (distance >= 0 && distance < 6) {
            points = scoring[distance]
          }

          return { ...team, distance, points }
        })
        .sort((a, b) => sortByProperty(a, b, 'distance', 'ASC')) ??
      [].slice(0, maxItems)
    )
  }, [game, userPrediction, gamePredictions, maxItems])

  return (
    <div>
      <GamePredictionsRenderer
        user={user}
        game={game}
        userPrediction={userPrediction}
        listData={sortedPredictions}
        isLoading={isLoadingRoundGamePredictions}
      />
      {user.admin &&
        sortedPredictions.length > 1 &&
        gamePredictions?.average > 0 && (
          <>
            <Box p={2} />
            <HBox align="center-right">
              <span style={{ color: colors.disabled }}>
                Community: ~{Math.round(gamePredictions?.average)} (admin only)
              </span>
            </HBox>
          </>
        )}
    </div>
  )
}

const GamePredictionsRenderer = ({
  user,
  game,
  userPrediction,
  listData,
  isLoading,
}) => {
  /*   if (isLoading) {
    return (
      <div>
        <DarkListLoading />
      </div>
    )
  } */

  const columns = [
    {
      flex: 6,
      ColComponent: ({ colData }) => {
        return (
          <HBox style={{ position: 'relative' }}>
            <TeamName name={colData.entry.name} teamId={colData.entry.id} />
            <WinnerCrown entry={colData.entry} />
          </HBox>
        )
      },
      align: 'left',
    },
    {
      ColComponent: ({ colData }) => {
        return (
          <>
            <span
              style={{
                fontSize: '19px',
                fontWeight: 'bold',
              }}
            >
              {colData.entry.score}{' '}
              {game.metacritic > 0 && (
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: primitiveColors.pColorYellow400,
                    marginLeft: spacing.xs,
                    verticalAlign: 'middle',
                  }}
                >
                  {colData.entry.points}p
                </span>
              )}
            </span>
          </>
        )
      },
    },
  ]

  const gameLocked = isGameLocked(game)

  let listSubLabel = ''

  if (!isGameReleased(game)) {
    if (user.isAuthenticated) {
      if (userPrediction) {
        listSubLabel = ''
      } else {
        if (gameLocked) {
          listSubLabel =
            'No more predictions can be made. Too close to release.'
        } else {
          listSubLabel =
            'Enter your prediction to see all community predictions.'
        }
      }
    } else {
      listSubLabel =
        'Sign in to view predictions and enter your prediction for this review score.'
    }
  } else {
    if (listData.length === 0) {
      listSubLabel = 'No predictions were made.'
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {listData.length > 0 && (
        <DarkList
          isLoading={isLoading}
          list={listData}
          columns={columns}
          /* markFn={(entry) => entry.id === user?.team?.id} */
        ></DarkList>
      )}
      {listSubLabel && <Text>{listSubLabel}</Text>}
    </div>
  )
}
