import { Typography } from '@mui/material'
import { GameName } from 'Components/game/gameName'
import { VBox } from 'Components/layout'
import { CSTooltip } from 'Components/visual/csTooltip'
import { DarkList } from 'Components/visual/darkList'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  findLowestScoredGame,
  isDropAfterReleaseAllowed,
  isDropAllowed,
} from 'util/gameLogic'
import { sortByProperty } from 'util/sort'
import { ReleaseDateFormatter } from './releaseDateFormatter'

export const UserReadonlyGamesList = ({ selectedGames, user, userGames }) => {
  const sortedGames = useMemo(() => {
    let sortedGames =
      selectedGames
        ?.filter((game) => game.metacritic !== null || user.admin)
        ?.sort((a, b) => sortByProperty(a, b, 'released', 'ASC'))
        .map((game) => {
          return {
            ...game,
            sharedGame: !!userGames?.find(
              (userGame) => userGame.id === game.id
            ),
            dropAllowed: isDropAllowed(game),
            dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
          }
        }) ?? []

    const { scoredGamesCount, lowestScoreGameIndex } =
      findLowestScoredGame(sortedGames)

    if (scoredGamesCount >= 10 && lowestScoreGameIndex !== null) {
      sortedGames[lowestScoreGameIndex].lowest = true
    }

    return sortedGames
  }, [selectedGames, user, userGames])

  // Columns
  const columns = [
    {
      ColComponent: ({ colData }) => {
        function ContentComponent() {
          return (
            <>
              <Typography>
                <strong>Lowest scoring game</strong>
                <br />
                At the end of the round the lowest scoring game won't count
                towards your total. This includes a game not scoring at all.
              </Typography>
            </>
          )
        }

        return (
          <VBox
            align="center-left"
            style={{ opacity: colData.entry.metacritic === null ? 0.5 : 1 }}
          >
            <GameName game={colData.entry} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <ReleaseDateFormatter releaseDate={colData.entry.released} />
              {colData.entry.metacritic &&
                (colData.entry.lowest ? (
                  <CSTooltip
                    id={'score' + colData.entry.id}
                    text={<ContentComponent />}
                  >
                    <ScoreBadge
                      score={colData.entry.metacritic}
                      lowestScored={colData.entry.lowest}
                      text="Score"
                    />
                  </CSTooltip>
                ) : (
                  <ScoreBadge
                    game={colData.entry}
                    lowestScored={colData.entry.lowest}
                    text="Score"
                  />
                ))}
            </div>
          </VBox>
        )
      },
    },
  ]

  const navigate = useNavigate()
  return (
    <>
      <DarkList
        stackOnMobile={false}
        hoverEffect="scaleFX"
        label={`Scored games`}
        /*         subLabel={`${sortedGames.length}/${gameLimit} scored`}
        progress={(sortedGames.length / gameLimit) * 100} */
        emptyText={<>No games scored yet for this round.</>}
        list={sortedGames}
        onRowClick={(rowData) => navigate('/game/' + rowData.slug)}
        columns={columns}
        markFn={(entry) => entry.sharedGame}
      ></DarkList>

      <p>
        The lowest scoring game in a round will not be counted if you score 10
        games.
      </p>
    </>
  )
}
