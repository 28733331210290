import { useContext } from 'react'
import { useUID } from 'react-uid'

import { CacheContext } from './cacheContext'

const useCachedData = (
  url,
  initialValue,
  observe,
  transform,
  refreshIntervalMs,
  method,
  condition = null
) => {
  const { getCachedItem } = useContext(CacheContext)

  const requestId = useUID()

  if (condition === false) {
    return [initialValue, null]
  }

  const item = getCachedItem(
    requestId,
    url,
    initialValue,
    observe,
    transform,
    refreshIntervalMs,
    method,
    condition
  )

  return [item.isLoading, item.value]
}

export default useCachedData
