import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { CSButton } from './CSButton'
import { GameName } from './gameName'

export const GamePredictionCardContent = ({ game }) => {
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: 'rgba(0,0,0, 0.8)',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <ChildSpacerVAll>
        <div style={{ height: '60px', fontSize: '16px' }}>
          <GameName game={game} />
        </div>
        {!user.isAuthenticated && (
          <>
            <Breakpoint customQuery={query.tabletDown}>
              <CSButton
                label="Sign in to predict review score"
                onClick={() => {
                  navigate('/user')
                }}
              ></CSButton>
            </Breakpoint>
            <Breakpoint customQuery={query.tabletUp}>
              <SubtleText>Sign in to enter your prediction.</SubtleText>
            </Breakpoint>
          </>
        )}
        {user.isAuthenticated && (
          <>
            <CSButton
              label="Predict this game"
              onClick={() => {
                navigate('/game/' + game.slug)
              }}
            ></CSButton>
          </>
        )}
      </ChildSpacerVAll>
    </div>
  )
}
