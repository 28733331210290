import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { HBox } from 'Components/layout'
import { colors } from 'styles/colors'
import { timing } from 'styles/animation'
import shape from 'styles/shape'
import { fontWeight } from 'styles/fontWeight'

const PREFIX = 'IconButton'

const classes = {
  iconButton: `${PREFIX}-iconButton`,
}

const Root = styled('button')({
  [`&.${classes.iconButton}`]: {
    padding: 0,
    fontFamily: 'inherit',
    fontSize: '16px',
    fontWeight: fontWeight.bold,
    textDecoration: 'none',
    color: colors.black,
    border: 'none',
    backgroundColor: colors.theme,
    borderRadius: shape.borderRadiusSmall,
    transition: `all ${timing.hoverTransition}`,
    width: '100%',
    cursor: 'pointer',
    height: '48px',

    '&:hover:not(:disabled)': {
      filter: 'brightness(110%)',
    },

    '&:active:not(:disabled)': {
      transform: 'translateY(2px)',
    },

    '&:disabled': {
      filter: 'brightness(75%)',
      cursor: 'not-allowed',
    },
  },
})

export const IconButton = ({
  label,
  icon,
  loading,
  onClick,
  disabled,
  backgroundColor,
}) => {
  return (
    <Root
      className={classes.iconButton}
      style={{
        position: 'relative',
        backgroundColor: backgroundColor ?? colors.theme,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <HBox align="center-left">
        <span
          className="material-symbols-rounded"
          style={{
            marginLeft: 8,
            marginTop: 1,
            fontSize: '24px',
            visibility: loading ? 'hidden' : 'visible',
          }}
        >
          {icon}
        </span>
        {loading && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              color: colors.black,
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          />
        )}
        <span
          style={{
            position: 'absolute',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            opacity: loading ? 0.3 : 1,
          }}
        >
          {label}
        </span>
      </HBox>
    </Root>
  )
}
