import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useGameFilter } from 'store/game/gameFilterContext'
import { useAuth } from 'store/user/authContext'

export const GameSort = () => {
  const { gameSortMode, setGameSortMode, gameFilter, GAME_SORT_OPTIONS } =
    useGameFilter()

  const onFilterChange = (event) => {
    setGameSortMode(event.target.value)
  }

  const { user } = useAuth()

  const SORT_HEADING = 'Sort by'

  return (
    <>
      {(gameFilter.releaseStatus === 'ALL' ||
        gameFilter.releaseStatus === 'RELEASED' ||
        gameFilter.releaseStatus === 'REVIEWED') && (
        <FormControl>
          <h4>{SORT_HEADING}</h4>
          <RadioGroup
            aria-labelledby="game sort mode"
            value={gameSortMode}
            name="game-sort-group"
            onChange={onFilterChange}
          >
            <FormControlLabel
              value={GAME_SORT_OPTIONS.releaseDate}
              control={<Radio />}
              label={
                GAME_SORT_OPTIONS.releaseDate +
                (gameFilter.releaseStatus === 'RELEASED' ||
                gameFilter.releaseStatus === 'REVIEWED'
                  ? ' Z-A'
                  : ' A-Z')
              }
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.picks}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.picks + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.likelyToScore}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.likelyToScore + ' Z-A'}
            />
            {user.isAuthenticated && (
              <FormControlLabel
                value={GAME_SORT_OPTIONS.yourPrediction}
                control={<Radio />}
                label={GAME_SORT_OPTIONS.yourPrediction + ' Z-A'}
              />
            )}
            <FormControlLabel
              value={GAME_SORT_OPTIONS.communityPrediction}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.communityPrediction + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.score}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.score + ' Z-A'}
            />
          </RadioGroup>
        </FormControl>
      )}
      {gameFilter.releaseStatus === 'UPCOMING' && (
        <FormControl>
          <h4>{SORT_HEADING}</h4>
          <RadioGroup
            aria-labelledby="game sort mode"
            value={gameSortMode}
            name="game-sort-group"
            onChange={onFilterChange}
          >
            <FormControlLabel
              value={GAME_SORT_OPTIONS.releaseDate}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.releaseDate + ' A-Z'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.picks}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.picks + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.likelyToScore}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.likelyToScore + ' Z-A'}
            />
            {user.isAuthenticated && (
              <FormControlLabel
                value={GAME_SORT_OPTIONS.yourPrediction}
                control={<Radio />}
                label={GAME_SORT_OPTIONS.yourPrediction + ' Z-A'}
              />
            )}
            <FormControlLabel
              value={GAME_SORT_OPTIONS.communityPrediction}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.communityPrediction + ' Z-A'}
            />
          </RadioGroup>
        </FormControl>
      )}
      {gameFilter.releaseStatus === 'NOT_PREDICTED' && (
        <FormControl>
          <h4>{SORT_HEADING}</h4>
          <RadioGroup
            aria-labelledby="game sort mode"
            value={gameSortMode}
            name="game-sort-group"
            onChange={onFilterChange}
          >
            <FormControlLabel
              value={GAME_SORT_OPTIONS.releaseDate}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.releaseDate + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.picks}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.picks + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.likelyToScore}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.likelyToScore + ' Z-A'}
            />
            <FormControlLabel
              value={GAME_SORT_OPTIONS.communityPrediction}
              control={<Radio />}
              label={GAME_SORT_OPTIONS.communityPrediction + ' Z-A'}
            />
          </RadioGroup>
        </FormControl>
      )}
    </>
  )
}
