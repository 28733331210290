import { HBox, VBox } from 'Components/layout'
import { useAuth } from 'store/user/authContext'
import { getGamePredictionScore } from 'util/gameLogic'
import { CSTooltip } from '../csTooltip'
import BronzeImg from './bronze.png'
import GoldImg from './gold.png'
import IronImg from './iron.png'
import { PredictionBadgeMini } from './predictionBadgeMini'
import SilverImg from './silver.png'
import UltraImg from './ultra.png'
import { semanticMedia } from 'styles/semanticMedia'

export const ScoreBadge = ({
  score,
  game,
  lowestScored,
  customValue,
  variant,
  prediction,
  disablePrediction,
  text,
}) => {
  let ImgComponent = <></>

  const { user } = useAuth()

  let finalScore = score || game?.metacritic

  if (!disablePrediction && (!finalScore || prediction)) {
    const predictionScore = prediction || getGamePredictionScore(user, game)

    if (predictionScore > 0) {
      return <PredictionBadgeMini score={predictionScore} tooltipId={game.id} />
    }

    return ImgComponent
  }

  if (!finalScore) {
    return ImgComponent
  }

  if (finalScore >= 90) {
    ImgComponent = UltraImg
  } else if (finalScore >= 80) {
    ImgComponent = GoldImg
  } else if (finalScore >= 70) {
    ImgComponent = SilverImg
  } else if (finalScore >= 60) {
    ImgComponent = BronzeImg
  } else {
    ImgComponent = IronImg
  }

  const Badge = () => {
    return (
      <img
        src={ImgComponent}
        style={{
          maxWidth: '32px',
          marginRight: variant === 'HORIZONTAL' ? '4px' : '0',
          opacity: lowestScored ? 0.5 : 1,
        }}
        alt="score badge"
      />
    )
  }

  const Score = () => {
    return (
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '19px',
          opacity: lowestScored ? 0.3 : 1,
          color: semanticMedia.contrastHigh,
        }}
      >
        {value}
      </span>
    )
  }

  const value = customValue || customValue === 0 ? customValue : finalScore

  const tooltipText = <>{text}</>

  const selectedVariant = variant ?? 'HORIZONTAL'
  if (selectedVariant === 'HORIZONTAL') {
    return (
      <>
        <CSTooltip text={tooltipText}>
          <span>
            <HBox align="center-right" flexType="hug">
              <Badge />
              <Score />
            </HBox>
          </span>
        </CSTooltip>
      </>
    )
  } else if (selectedVariant === 'VERTICAL') {
    return (
      <>
        <CSTooltip text={tooltipText}>
          <span>
            <VBox align="center">
              <Badge />
              <Score />
            </VBox>
          </span>
        </CSTooltip>
      </>
    )
  }

  return null
}
