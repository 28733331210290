import { primitiveNumbers } from "./primitiveNumbers"

export const semanticRadius = {
  /// from design system
  radiusXsmall: primitiveNumbers.pNum4,
  radiusSmall: primitiveNumbers.pNum8,
  radiusMedium: primitiveNumbers.pNum16,
  radiusLarge: primitiveNumbers.pNum24,
  radiusFull: primitiveNumbers.pNum96,
}
