import { Box, Button } from '@mui/material'
import { signOutFromFirebase } from 'Components/firebase'
import { CSButton } from 'Components/game/CSButton'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { DarkList } from 'Components/visual/darkList'
import { SubtleText } from 'Components/visual/SubtleText'
import { Text } from 'Components/visual/text'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useFriends } from 'store/game/friendsContext'
import { useUINotifs } from 'store/game/uiNotificationsContext'
import { formatNiceDate } from 'util/date'

export const UserFriends = () => {
  const navigate = useNavigate()
  const { setGameActionSnackState } = useUINotifs()
  const { isLoadingFriends, friends, onManageFriend } = useFriends()
  const [savingFriendId, setSavingFriendId] = useState(null)

  const manageFriend = async (friendUserId, action) => {
    const friend = {
      friendUserId,
      action,
    }

    setSavingFriendId(friendUserId + action)
    await apiPost(apiEndpoints.user_friend_manage, friend)
      .then((data) => {
        setSavingFriendId(null)
        onManageFriend(friendUserId, action)

        let message = ''
        switch (action) {
          case 'ACCEPT':
            message = 'Friend request accepted'
            break
          case 'IGNORE':
            message = 'Friend request ignored'
            break
          case 'DELETE':
            message = 'Friend deleted'
            break
          default:
            message = 'Unknown action'
        }

        setGameActionSnackState({
          isOpen: true,
          text: message,
          severity: 'success',
        })
      })
      .catch((error) => {
        setSavingFriendId(null)
        if (error.status === 403) {
          console.log('Not logged in')
          setGameActionSnackState({
            isOpen: true,
            text: `You need to log in first`,
            severity: 'info',
          })
          signOutFromFirebase()
        } else if (error) {
          console.error(error)
          setGameActionSnackState({
            isOpen: true,
            text: `Could not manage friend due to a server error`,
            severity: 'error',
          })
        }
      })
  }

  const columns = [
    {
      ColComponent: ({ colData }) => {
        return (
          <HBox align="space-between">
            <VBox>
              {colData.entry.name}
              {colData.entry.accepted && (
                <SubtleText>
                  Friends since {formatNiceDate(colData.entry.accepted)}
                </SubtleText>
              )}
              {colData.entry.status === 'REQUEST_SENT' && (
                <SubtleText>Request sent</SubtleText>
              )}
              {colData.entry.status === 'IGNORED' && (
                <SubtleText>Ignored</SubtleText>
              )}
            </VBox>
            <ChildSpacerH>
              {colData.entry.status === 'REQUEST_RECEIVED' && (
                <CSButton
                  saving={savingFriendId === colData.entry.userId + 'IGNORE'}
                  onClick={(event) => {
                    manageFriend(colData.entry.userId, 'IGNORE')
                  }}
                  label="Ignore"
                />
              )}
              {colData.entry.status === 'REQUEST_RECEIVED' && (
                <CSButton
                  saving={savingFriendId === colData.entry.userId + 'ACCEPT'}
                  variant="contained"
                  onClick={(event) => {
                    manageFriend(colData.entry.userId, 'ACCEPT')
                  }}
                  label="Accept"
                />
              )}
              {(colData.entry.status === 'ACCEPTED' ||
                colData.entry.status === 'REQUEST_SENT' ||
                colData.entry.status === 'IGNORED' ||
                colData.entry.status === 'BLOCKED') && (
                <ChildSpacerH>
                  <CSButton
                    saving={savingFriendId === colData.entry.userId + 'DELETE'}
                    variant="text"
                    onClick={(event) => {
                      manageFriend(colData.entry.userId, 'DELETE')
                    }}
                    label="Delete"
                  />
                  <CSButton
                    saving={savingFriendId === colData.entry.userId}
                    onClick={(event) => {
                      navigate('/team/' + colData.entry.userId)
                    }}
                    label="View"
                  />
                </ChildSpacerH>
              )}
            </ChildSpacerH>
          </HBox>
        )
      },
    },
  ]

  return (
    <>
      <Text>
        Add friends to view a separate leaderboard with only your friends.
      </Text>
      <Box p={4} />
      <DarkList
        isLoading={isLoadingFriends}
        stackOnMobile={false}
        hoverEffect="scaleFX"
        emptyText={
          <>
            <ChildSpacerVAll>
              <div>
                You haven't added any friends yet. Add friends from the
                Standings page.
              </div>
            </ChildSpacerVAll>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/leaderboards')
              }}
              style={{ width: '100%' }}
            >
              Go to Standings
            </Button>
          </>
        }
        list={friends}
        columns={columns}
      ></DarkList>
    </>
  )
}
