import { Button, CircularProgress } from '@mui/material'
export const CardButton = ({
  label,
  icon,
  onClick,
  saving,
  disabled,
  color,
  style,
}) => {
  return (
    <Button
      disabled={!!disabled}
      onClick={onClick}
      style={{
        position: 'absolute',
        ...style,
      }}
    >
      {saving && (
        <CircularProgress disableShrink size={16} style={{ marginRight: 4 }} />
      )}
      {!saving && <span className="material-symbols-rounded">{icon}</span>}{' '}
      {label}
    </Button>
  )
}
