import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { useRounds } from 'store/game/roundContext'
import { fontSize } from 'styles/fontSize'
import { fontWeight } from 'styles/fontWeight'
import { semanticMedia } from 'styles/semanticMedia'
import {
  formatISODate,
  formatLongDate,
  isWithinTwoWeeksBefore,
} from 'util/date'
import { buildTodayZero } from '../../util/date'
import { TextSqueezer } from './textWrapper'

export const NextRoundMessage = () => {
  const { mainRounds } = useRounds()
  const nextRound = mainRounds.next

  if (!isWithinTwoWeeksBefore(nextRound.startDate, buildTodayZero())) {
    return null
  }

  return (
    <HeroMessage>
      <ChildSpacerVAll>
        <span
          style={{
            fontSize: fontSize.large,
            fontWeight: fontWeight.medium,
            color: semanticMedia.contrastHigh,
            lineHeight: '1.5',
          }}
        >
          The round is about to end!
        </span>
      </ChildSpacerVAll>
      <TextSqueezer>
        <p>
          Make your final picks and predictions for{' '}
          {mainRounds.current.fullName} or start picking or predicting games for
          the next round, which starts on{' '}
          {formatLongDate(formatISODate(nextRound.startDate))}.
        </p>
      </TextSqueezer>
    </HeroMessage>
  )
}
