import { semanticRadius } from 'styles/semanticRadius'
import { semanticSurface } from 'styles/semanticSurface'
import { spacing } from 'styles/spacing'

export const HeroMessage = ({ padding, children }) => {
  return (
    <>
      <div
        style={{
          borderRadius: semanticRadius.radiusSmall,
          padding: padding ?? spacing.lg,
          backgroundColor: semanticSurface.surfaceThemeLow,
        }}
      >
        {children}
      </div>
    </>
  )
}
