import { colors } from 'styles/colors'
import { isBomb, isCookie, isShadowDrop } from 'util/gameLogic'
import { CSTooltip } from 'Components/visual/csTooltip'

const GoldenComponent = () => (
  <>
    <span
      className="material-symbols-rounded"
      style={{ fontSize: '18px', color: colors.gold }}
    >
      cookie
    </span>
  </>
)

const BombComponent = () => (
  <>
    <span
      className="material-symbols-rounded"
      style={{ fontSize: '18px', color: colors.warning }}
    >
      bomb
    </span>
  </>
)

const ShadowDropComponent = () => (
  <>
    <span
      className="material-symbols-rounded"
      style={{ fontSize: '18px', color: colors.theme }}
    >
      ev_shadow
    </span>
  </>
)

export const SurpriseIcon = ({ game }) => {
  const isCookieGame = isCookie(game)
  const isBombGame = isBomb(game)
  const isShadowDropGame = isShadowDrop(game)

  const iconStyle = {
    marginRight: '4px',
    marginTop: '2px',
    display: 'inline-block',
  }

  return (
    <CSTooltip
      id={game.id}
      text={
        <span>
          {isCookieGame && (
            <>
              Cookies represent games that surprised by being much better than
              expected.
            </>
          )}
          {isBombGame && (
            <>
              Bombs represent games that surprised by being far worse than
              expected.
            </>
          )}
          {isShadowDropGame && (
            <>
              Shadowdrop. This game was announced and released immediately
              making it impossible to pick.
            </>
          )}
        </span>
      }
    >
      <span>
        {isCookieGame && (
          <span style={iconStyle}>
            <GoldenComponent />
          </span>
        )}
        {isBombGame && (
          <span style={iconStyle}>
            <BombComponent />
          </span>
        )}
        {isShadowDropGame && (
          <span style={iconStyle}>
            <ShadowDropComponent />
          </span>
        )}
      </span>
    </CSTooltip>
  )
}
