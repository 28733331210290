import { useMediaQuery } from "@mui/material"
import { ChildSpacerVAll } from "Components/layout/ChildSpacer"
import { useGameFilter } from "store/game/gameFilterContext"
import { useAuth } from "store/user/authContext"
import { query } from "styles/mediaQueries"
import { CSButton } from "./CSButton"
import { GameFilterCategory } from "./gameFilterCategory"
import { GameFilterGenre } from "./gameFilterGenre"
import { GameFilterName } from "./gameFilterName"
import { GameFilterPlatforms } from "./gameFilterPlatforms"
import { GameFilterReleaseStatus } from "./gameFilterReleaseStatus"
import { GameFilterScore } from "./gameFilterScore"
import { GameFilterUnlikely } from "./gameFilterUnlikely"
import { GameFilterWatchlist } from "./gameFilterWatchlist"
import { GameSort } from "./gameSort"

export const GameFilter = () => {
  const { gameFilter, setGameFilter, clearGameFilter } = useGameFilter()
  const isMobile = useMediaQuery(query.mobile)
  const { user } = useAuth()

  return (
    <ChildSpacerVAll>
      <h3>Filter</h3>
      {!isMobile && <GameFilterName />}
      <div>
        <GameFilterUnlikely
          gameFilter={gameFilter}
          setGameFilter={setGameFilter}
        />
        {user.isAuthenticated && (
          <GameFilterWatchlist
            gameFilter={gameFilter}
            setGameFilter={setGameFilter}
          />
        )}
      </div>
      <GameFilterReleaseStatus
        orientation="vertical"
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameSort />
      <GameFilterCategory
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      {(gameFilter.releaseStatus === "ALL" ||
        gameFilter.releaseStatus === "RELEASED" ||
        gameFilter.releaseStatus === "REVIEWED") && (
        <GameFilterScore
          gameFilter={gameFilter}
          setGameFilter={setGameFilter}
        />
      )}
      <GameFilterPlatforms
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterGenre gameFilter={gameFilter} setGameFilter={setGameFilter} />
      <CSButton onClick={() => clearGameFilter()} label="Reset all filters" />
    </ChildSpacerVAll>
  )
}
