import {
  ChildSpacerH,
  ChildSpacerHAll,
  ChildSpacerVAll,
} from 'Components/layout/ChildSpacer'
import { colors } from './../../styles/colors'
import { HBox } from 'Components/layout'
import { fontSize } from 'styles/fontSize'

export const HowToPlayPanel = ({ gameLimitOverride }) => {
  const tipsList = [
    {
      primary: 'Pick 10 video games',
      /* secondary: `Pick and drop games from your profile during a quarter of a year.`, */
    },
    {
      primary: 'Score points when reviews drop',
      /* secondary: 'When a game gets metacritic reviews you get points.', */
    },
    {
      primary: 'Strive for #1 on the Picks leaderboard',
      /* secondary: `Hopefully your profile has the most points at the end of a quarter.`, */
    },
  ]

  return (
    <ChildSpacerVAll>
      <HBox align="center-left">
        <ChildSpacerHAll style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className="material-symbols-rounded"
            style={{ fontSize: fontSize.xlarge, color: colors.theme }}
          >
            diamond
          </span>
          <h3 style={{ padding: 0 }}>Picks</h3>
        </ChildSpacerHAll>
      </HBox>
      <ul>
        {tipsList.map((tip, index) => {
          return (
            <ChildSpacerH
              key={index}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 4, color: colors.theme }}
              >
                circle
              </span>
              <span style={{ fontSize: '14px', verticalAlign: 'middle' }}>
                {tip.primary}
              </span>
            </ChildSpacerH>
          )
        })}
      </ul>
    </ChildSpacerVAll>
  )
}
