import { Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { semanticMedia } from 'styles/semanticMedia'

export const MainMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedMenu, setSelectedMenu] = useState(0)

  useEffect(() => {
    switch (location.pathname.split('/')[1]) {
      case 'games':
        setSelectedMenu(1)
        break
      case 'teamPredictions':
      case 'leaderboards':
        setSelectedMenu(2)
        break
      case 'how-to-play':
        setSelectedMenu(3)
        break
      case '':
        setSelectedMenu(0)
        break
      default:
        setSelectedMenu(false)
        break
    }
  }, [location.pathname])

  const handleChange = (event, newValue) => {
    setSelectedMenu(newValue)
  }

  const menuTabStyle = {
    fontSize: '18px',
    minWidth: 'auto',
    textTransform: 'none',
    textAlign: 'left',
    padding: 0,
  }

  const sxStyle = {
    justifyContent: 'start',
    transition: '0.3s all',
    '&:hover': {
      color: semanticMedia.accentTheme,
    },
  }

  return (
    <Tabs value={selectedMenu} orientation="vertical" onChange={handleChange}>
      <Tab
        label="Home"
        icon={
          <span
            className="material-symbols-rounded"
            style={{ marginRight: '8px' }}
          >
            home
          </span>
        }
        iconPosition="start"
        style={{ ...menuTabStyle }}
        sx={sxStyle}
        onClick={() => navigate('/')}
      />
      <Tab
        label="Games"
        icon={
          <span
            className="material-symbols-rounded"
            style={{ marginRight: '8px' }}
          >
            sports_esports
          </span>
        }
        iconPosition="start"
        sx={sxStyle}
        style={{ ...menuTabStyle }}
        onClick={() => navigate('/games')}
      />
      <Tab
        label="Standings"
        icon={
          <span
            className="material-symbols-rounded"
            style={{ marginRight: '8px' }}
          >
            leaderboard
          </span>
        }
        iconPosition="start"
        sx={sxStyle}
        style={{ ...menuTabStyle }}
        onClick={() => navigate('/leaderboards')}
      />
    </Tabs>
  )
}
