import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { HBox } from 'Components/layout'
import { SubtleText } from 'Components/visual/SubtleText'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { useAuth } from 'store/user/authContext'

export const LeaderboardsFilter = ({ gameFilter, setGameFilter }) => {
  const { leaderboardsFilter, setLeaderboardsFilter, LEADERBOARD_FILTER } =
    useLeaderboards()

  const handleChange = (event) => {
    setLeaderboardsFilter(event.target.value)
  }

  const { user } = useAuth()

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="leaderboards filter"
          value={leaderboardsFilter}
          name="radio-buttons-group"
          onChange={handleChange}
          row
        >
          <HBox align="center">
            <FormControlLabel
              value={LEADERBOARD_FILTER.global.id}
              control={<Radio />}
              label={LEADERBOARD_FILTER.global.label}
            />
            <Box p={3} />
            {user.isAuthenticated && (
              <FormControlLabel
                value={LEADERBOARD_FILTER.friends.id}
                control={<Radio />}
                label={LEADERBOARD_FILTER.friends.label}
              />
            )}
            {!user.isAuthenticated && (
              <SubtleText>Sign in to view friends only standings</SubtleText>
            )}
          </HBox>
        </RadioGroup>
      </FormControl>
    </>
  )
}
