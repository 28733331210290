import { GameName } from 'Components/game/gameName'
import { HBox, VBox } from 'Components/layout'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { ReleaseDateFormatter } from 'pages/home/profilePanel/releaseDateFormatter'
import { useMemo } from 'react'
import { useGames } from 'store/game/gameContext'
import { HomeList } from './homeList'
import { ChildSpacerH } from 'Components/layout/ChildSpacer'
import { usePredictions } from 'store/game/predictionsContext'
import {
  getGamePredictions,
  getGamePredictionScore,
  isGameReleased,
} from 'util/gameLogic'
import { CSTooltip } from 'Components/visual/csTooltip'
import { PredictionBadgeMini } from 'Components/visual/scoreBadge/predictionBadgeMini'
import { useAuth } from 'store/user/authContext'
import { semanticMedia } from 'styles/semanticMedia'

export const BestScoredList = () => {
  return <BestScoredListRenderer />
}

const BestScoredListRenderer = () => {
  const { isLoadingAvailableGames, bestScoredList, selectedGames } = useGames()
  const { roundGamePredictions } = usePredictions()
  const { user } = useAuth()

  const sortedGames = useMemo(() => {
    let sortedGames =
      bestScoredList.map((game) => {
        return {
          ...game,
          sharedGame: !!selectedGames?.find(
            (userGame) => userGame.id === game.id
          ),
        }
      }) ?? []

    return sortedGames
  }, [bestScoredList, selectedGames])

  return (
    <HomeList
      label="Top 10 games"
      previewItemLimit={10}
      list={sortedGames}
      isLoading={isLoadingAvailableGames}
      markFn={(entry) => entry.sharedGame}
      columns={[
        {
          ColComponent: ({ colData }) => {
            return (
              <VBox>
                <GameName game={colData.entry} />
                <ReleaseDateFormatter
                  releaseDate={colData.entry.released}
                  absolute
                />
              </VBox>
            )
          },
        },
        {
          flex: 1,
          align: 'right',
          ColComponent: ({ colData }) => {
            const gamePredictions = getGamePredictions(
              roundGamePredictions,
              colData.entry.id
            )
            return (
              <HBox align="center-right">
                <ChildSpacerH>
                  {gamePredictions?.average > 0 && (
                    <CSTooltip text={<>Community average score prediction</>}>
                      <span
                        style={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          paddingRight: '4px',
                        }}
                      >
                        <span style={{ position: 'absolute' }}>
                          <span
                            className="material-symbols-rounded"
                            style={{ opacity: '0.2' }}
                          >
                            Public
                          </span>
                        </span>
                        <span style={{ color: semanticMedia.contrastMedium }}>
                          {Math.round(gamePredictions?.average)}
                        </span>
                      </span>
                    </CSTooltip>
                  )}
                  {((user.isAuthenticated &&
                    getGamePredictionScore(user, colData.entry) > 0) ||
                    (!isGameReleased(colData.entry) &&
                      !colData.entry.metacritic)) && (
                    <PredictionBadgeMini
                      score={getGamePredictionScore(user, colData.entry)}
                    />
                  )}
                  <ScoreBadge game={colData.entry} text="Score" />
                </ChildSpacerH>
              </HBox>
            )
          },
        },
      ]}
    />
  )
}
