import { themeOpacity } from "styles/primitiveColors"
import { semanticInteractive } from "styles/semanticInteractive"
import { semanticRadius } from "styles/semanticRadius"

export const Bar = ({
  progress,
  height,
  color = semanticInteractive.fillThemeDefault,
}) => {
  const actualHeight = height ?? 4

  return (
    <div
      style={{
        height: actualHeight,
        width: "100%",
        backgroundColor: themeOpacity.pWhiteOpacity8,
        borderRadius: semanticRadius.radiusFull,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: actualHeight,
          width: `${progress > 100 ? 100 : progress}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  )
}
