const { Collapse, Box, Alert, AlertTitle } = require('@mui/material')

export const ErrorSummary = ({ errors, label, severity = 'error' }) => {
  const keys = Object.keys(errors)
  const keyCount = keys.length

  if (keyCount <= 0) {
    return null
  }

  return (
    <Collapse in={keyCount > 0}>
      <Box p={1} />
      <Alert severity={severity ?? 'error'}>
        <AlertTitle>{label}</AlertTitle>
        {Object.keys(errors).map((error, index) => (
          <div key={index}>{errors[error]}</div>
        ))}
      </Alert>
      <Box p={1} />
    </Collapse>
  )
}
