import { useTheme } from '@emotion/react'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { LeaderboardsSortButtonGroup } from 'Components/game/leaderboardsSortButtonGroup'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { BestScoredList } from 'Components/panes/bestScoredList'
import { FantasyLeaderboardList } from 'Components/panes/fantasyLeaderboardsList'
import { PredictorLeaderboardList } from 'Components/panes/predictorLeaderboardsList'
import React, { useEffect, useMemo } from 'react'
import { Breakpoint } from 'react-socks'
import SwipeableViews from 'react-swipeable-views'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { query } from 'styles/mediaQueries'
import { FantasyLeaderboardChart } from './fantasyLeaderboardChart'
import { semanticSurface } from 'styles/semanticSurface'
import { useNavigate, useParams } from 'react-router-dom'
import { useFriends } from 'store/game/friendsContext'
import { useAuth } from 'store/user/authContext'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export const RenderLeaderboards = () => {
  const { isLoadingLeaderboards, leaderboards, leaderboardsSortMode } =
    useLeaderboards()

  const navigate = useNavigate()

  const theme = useTheme()
  const { selectedBoard } = useParams()
  const [selectedTab, setSelectedTab] = React.useState(0)

  useEffect(() => {
    setSelectedTab(selectedBoard === 'predictor' ? 1 : 0)
  }, [selectedBoard])

  const handleTabChange = (event, newValue) => {
    navigate(`/leaderboards/${newValue === 0 ? 'fantasy' : 'predictor'}`)
  }

  const handleSwipeChange = (index) => {
    setSelectedTab(index)
  }

  return (
    <>
      <VBox>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="leaderboard tabs"
          style={{ maxWidth: '600px' }}
        >
          <Tab label="Picks" {...a11yProps(0)} />
          <Tab label="Predictions" {...a11yProps(1)} />
        </Tabs>
        <div
          style={{
            width: '100%',
            height: '1px',
            marginTop: '-1px',
            backgroundColor: semanticSurface.surfaceLayerHigh,
          }}
        ></div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selectedTab}
          onChangeIndex={handleSwipeChange}
        >
          <TabPanel value={selectedTab} index={0} dir={theme.direction}>
            <>
              <Box p={4} />
              <HBox align="center-right">
                <LeaderboardsSortButtonGroup />
              </HBox>
              <Box p={2} />
              <LeaderboardPanel
                isLoading={isLoadingLeaderboards}
                leaderboards={leaderboards}
                selectedLeaderboardType={'fantasy'}
                leaderboardsSortMode={leaderboardsSortMode}
              />
              <Box p={5} />
              <BestScoredList />
            </>
          </TabPanel>
          <TabPanel value={selectedTab} index={1} dir={theme.direction}>
            <>
              <Box p={5} />
              <LeaderboardPanel
                isLoading={isLoadingLeaderboards}
                leaderboards={leaderboards}
                selectedLeaderboardType={'predictor'}
                leaderboardsSortMode={leaderboardsSortMode}
              />
            </>
          </TabPanel>
        </SwipeableViews>
      </VBox>
      <Breakpoint customQuery={query.tabletUp}>
        <Box p={2}></Box>
        <FantasyLeaderboardChart />
      </Breakpoint>
      <Box p={2}></Box>
    </>
  )
}

function LeaderboardPanel({
  isLoading,
  leaderboards,
  selectedLeaderboardType,
  leaderboardsSortMode,
}) {
  const { selectedRound } = useRounds()
  const { leaderboardsFilter, LEADERBOARD_FILTER } = useLeaderboards()
  const { friends } = useFriends()
  const leaderboardRound = leaderboards[selectedRound.id]
  const { user } = useAuth()

  const leaderboardList = useMemo(() => {
    const actualLeaderboard =
      (leaderboardRound && leaderboardRound[selectedLeaderboardType]) ?? []

    if (leaderboardsFilter === LEADERBOARD_FILTER.friends.id) {
      return actualLeaderboard.filter((entry) =>
        friends.some(
          (friend) =>
            entry.userId === user.id ||
            (friend.status === 'ACCEPTED' && friend.userId === entry.userId)
        )
      )
    } else {
      return actualLeaderboard
    }
  }, [
    user,
    leaderboardsFilter,
    leaderboardRound,
    selectedLeaderboardType,
    LEADERBOARD_FILTER,
    friends,
  ])

  if (isLoading) {
    return (
      <VBox align="center">
        <Box p={2} />
        <CircularProgress />
        <Box p={1} />
        Loading leaderboards
      </VBox>
    )
  }

  if (!leaderboardRound) {
    return <p>No leaderboard yet. Check back later.</p>
  }
  const selectedLeaderboard = leaderboardRound[selectedLeaderboardType]
  if (!selectedLeaderboard) {
    return <p>No leaderboard yet. Check back later.</p>
  }

  return (
    <>
      {selectedLeaderboardType === 'fantasy' && (
        <div>
          <Box p={1} />
          <FantasyLeaderboardList
            label={null}
            leaderboardList={leaderboardList}
            selectedRound={selectedRound ?? null}
            leaderboardsSortMode={leaderboardsSortMode}
          />
        </div>
      )}
      {selectedLeaderboardType === 'predictor' && (
        <div>
          <ChildSpacerV>
            <PredictorLeaderboardList
              label={null}
              leaderboardList={leaderboardList}
              selectedRound={selectedRound ?? null}
              leaderboardsSortMode={leaderboardsSortMode}
            />
          </ChildSpacerV>
        </div>
      )}
    </>
  )
}
