import { Skeleton } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { GamePredictionCard } from 'Components/game/gamePredictionCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Text } from 'Components/visual/text'
import { Link } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { usePredictGame } from 'store/game/userPredictGameContext'
import { useAuth } from 'store/user/authContext'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'

export const UserPredictList = () => {
  const { isLoadingAvailableGames } = useGames()
  const { user } = useAuth()

  const { gamesToPredict, gamesPossibleToPredict } = usePredictGame()

  const { selectedRound, mainRounds } = useRounds()

  const isCurrentRound = selectedRound.id === mainRounds.current.id

  if (!isCurrentRound) {
    return null
  }

  return (
    <ChildSpacerV
      style={{
        border: `1px solid ${semanticSurface.surfaceLayerMedium}`,
        borderRadius: 16,
        padding: 32,
      }}
    >
      <PreviewDrawer
        anchor="left"
        previewItemLimit={1}
        ContentComponent={PredictGameGrid}
        isLoading={isLoadingAvailableGames}
        gamesToPredict={gamesToPredict}
        gamesPossibleToPredict={gamesPossibleToPredict}
        user={user}
      />
    </ChildSpacerV>
  )
}

function PredictGameGrid({
  user,
  limit,
  isLoading,
  gamesToPredict,
  gamesPossibleToPredict,
}) {
  if (isLoading) {
    return (
      <>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{ borderRadius: 2 }}
          style={{ backgroundColor: semanticSurface.surfaceLayerLow }}
        />
      </>
    )
  }

  if (gamesPossibleToPredict.length <= 0) {
    return (
      <>
        <div style={{ color: semanticMedia.contrastMedium }}>
          <p>
            There are no games left to predict for the next two weeks right now.
          </p>
          <Text color="contrastLow">
            There may be more to predict under <Link to="/games">Games</Link> if
            you want to catch them all.
          </Text>
        </div>
      </>
    )
  }

  if (gamesToPredict.length <= 0) {
    return (
      <>
        <div style={{ color: semanticMedia.contrastMedium }}>
          <p>
            Way to go! You have predicted review scores for every game releasing
            in the next 2 weeks.
          </p>
          <Text color="contrastLow">
            There may be more to predict under <Link to="/games">Games</Link> if
            you want to catch them all.
          </Text>
        </div>
      </>
    )
  }

  return (
    <>
      {user.isAuthenticated && gamesToPredict.length > 0 && (
        <h3>
          {gamesToPredict.length} {gamesToPredict.length > 1 ? 'games' : 'game'}{' '}
          to predict
        </h3>
      )}

      <div style={{ color: semanticMedia.contrastMedium }}>
        Enter which exact review score you think the following game will
        receive. The closer you are, the more points you get!
      </div>
      <CardGrid
        limit={limit}
        list={gamesToPredict}
        isLoading={isLoading}
        Component={GamePredictionCard}
        readonly={true}
        emptyText="Way to go! You have predicted review scores for every game releasing
          in next two weeks. There may be more to predict under Games if you want to catch them all."
      />
    </>
  )
}
