import { Box } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { Cols } from 'Components/layout/cols'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { query } from 'styles/mediaQueries'
import { GameFilter } from './gameFilter'
import { GameFilterControls } from './gameFilterControls'
import { PicksSummary } from 'pages/home/profilePanel/picksSummary'
import { useAuth } from 'store/user/authContext'
import { CSButton } from './CSButton'
import { VBox } from 'Components/layout'

export const GameFilterView = () => {
  const { filteredSelectedGames, addPage, lastPage } = useGameFilter()
  const { isLoadingAvailableGames } = useGames()
  const { user } = useAuth()

  return (
    <>
      <Breakpoint customQuery={query.desktopLDown}>
        <GameFilterControls />
        <Box p={3}></Box>
        {user.isAuthenticated && (
          <>
            <PicksSummary placedOnProfile={false} />
            <Box p={5} />
          </>
        )}{' '}
        <CardGrid
          isLoading={isLoadingAvailableGames}
          list={filteredSelectedGames}
          animateDirection={-1}
        />
        {!lastPage && (
          <>
            <Box p={3}></Box>
            <VBox>
              <CSButton onClick={() => addPage()} label="Show more" />
            </VBox>
            <Box p={3}></Box>
          </>
        )}
      </Breakpoint>
      <Breakpoint customQuery={query.desktopLUp}>
        <Cols set="auto 256px" gap={96}>
          <div>
            <GameFilterControls />
            {user.isAuthenticated && (
              <>
                <PicksSummary placedOnProfile={false} />
                <Box p={5} />
              </>
            )}
            <CardGrid
              isLoading={isLoadingAvailableGames}
              list={filteredSelectedGames}
              animateDirection={-1}
            />
            {!lastPage && (
              <>
                <Box p={3}></Box>
                <VBox>
                  <CSButton onClick={() => addPage()} label="Show more" />
                </VBox>
                <Box p={3}></Box>
              </>
            )}
          </div>
          <GameFilter />
        </Cols>
      </Breakpoint>
    </>
  )
}
