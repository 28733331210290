import { CSButton } from 'Components/game/CSButton'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFriends } from 'store/game/friendsContext'

export const FriendRequests = () => {
  const navigate = useNavigate()
  const { friends } = useFriends()

  const friendRequests = useMemo(
    () =>
      friends?.filter((friend) => friend.status === 'REQUEST_RECEIVED') ?? [],
    [friends]
  )

  return (
    <>
      {friendRequests.length > 0 && (
        <CSButton
          variant="contained"
          onClick={() => {
            navigate('/team-manage/friends')
          }}
          label={
            friendRequests.length +
            (friendRequests.length === 1
              ? ' friend request'
              : ' friend requests')
          }
        ></CSButton>
      )}
    </>
  )
}
