import { useState } from "react"
import { semanticMedia } from "styles/semanticMedia"

export const ReadMore = ({ id, text, amountOfWords = 18 }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const splittedText = text.split(" ")
  const itCanOverflow = splittedText.length > amountOfWords
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(" ")
    : text
  const endText = splittedText.slice(amountOfWords - 1).join(" ")

  const handleKeyboard = (e) => {
    if (e.code === "Space" || e.code === "Enter") {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <div id={id}>
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>... </span>}
          {isExpanded && <span aria-hidden={!isExpanded}> {endText} </span>}

          <span
            style={{
              color: semanticMedia.contrastLow,
              cursor: "pointer",
              whiteSpace: "nowrap",
              textDecoration: "underline",
            }}
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Show less" : "Show more"}
          </span>
        </>
      )}
    </div>
  )
}
