import { Box } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { EndRoundMessage } from 'Components/visual/endRoundMessage'
import { HLine } from 'Components/visual/HLine'
import { MaintenanceMessage } from 'Components/visual/maintenanceMessage'
import { NextRoundMessage } from 'Components/visual/nextRoundMessage'
import { SubtleText } from 'Components/visual/SubtleText'
import { WinnerMessage } from 'Components/visual/winnerMessage'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'
import { formatShortDate } from 'util/date'

export const WhatsNewList = ({ limit, onButtonClick }) => {
  const newsList = [
    {
      date: formatShortDate('2024-12-28'),
      title: 'Added friends leaderboards!',
      updates: [
        'You can now add profiles as friends and then filter out friends only on the leaderboards.',
        'Various visual improvements.',
      ],
    },
    {
      date: formatShortDate('2024-11-30'),
      title: 'New home layout and more',
      updates: [
        'Updated Home, Games and your profile to more clearly tell you what you may wanna do.',
        'Your profile predictions view now match the one when viewing opponents in the predictions leaderboards.',
        'Made it possible to drop games that lost data',
      ],
    },
    {
      date: formatShortDate('2024-11-17'),
      title: 'Minor improvements and bug fixes',
      updates: [
        'The crown is back!',
        'Added next button to game page to quickly navigate to the next game.',
        'More informative home page.',
        'Visual improvements on leaderboards.',
        'Fixed issue when saving predictions being signed out.',
      ],
    },
    {
      date: formatShortDate('2024-10-26'),
      title: 'New swag!',
      text: `You can find some new merch on the spreadshirt link down in the footer with our new branding!`,
      updates: [],
    },
    {
      date: formatShortDate('2024-10-21'),
      title: 'Filtering and marking improvements and more',
      text: `Polished a few things. Keep posting #feedback on issues and we'll keep fixing.`,
      updates: [
        `We now filter out games releasing december 31st because they are never correct.`,
        `We now mark games you have picked under New reviews and your Predictions tab.`,
        `Added your and community guess to Top 10 picks under Standings.`,
        `Images are back on game cards on mobile.`,
        `Now showing "Too late to pick" on games you can't pick anymore.`,
        /* `Suggested predictions only show games releasing in the upcoming 2 weeks to nudge you to not predict them all in one day but keep checking back instead.``Fixed issue when signing in from the prediction suggestion card where you wouldn't be redirected to the correct page.`, */
        `And many small layout improvements for clarity and less eyesore.`,
      ],
    },
    {
      date: formatShortDate('2024-10-13'),
      title: 'Post layout update improvements',
      text: `Fixed a few layout issues introduced in the previous layout update. Keep posting #feedback on issues and we'll keep fixing.`,
      updates: [],
    },
    {
      date: formatShortDate('2024-09-21'),
      title: 'New branding and layout',
      text: `We've aimed for a more modern, colorful and clear design. Hope you like it! Let us know what you think in the discord server.`,
      updates: [],
    },
    {
      date: formatShortDate('2024-07-21'),
      updates: [
        `Added this site updates section to the home page.`,
        `We're now filtering all kinds of editions to avoid duplicates.`,
        `Added filters to hide visual novels and indie games.`,
      ],
    },
    {
      date: '2023-01-05',
      updates: [
        `v1.1.13: Several performance improvements. Some history in the UI had to be removed for now in the frontend. All data is still intact.`,
        `Next round is now available to view from the Games tab.`,
        `Several smaller UI improvements.`,
        `Improved robustness.`,
        `Bug fixes.`,
      ],
    },
    {
      date: '2022-01-06',
      updates: [
        `v1.1.10: Added top predictions list on home.`,
        `Added other profiles predictions and average to game dialog.`,
      ],
    },
    {
      date: '2022-01-06',
      updates: [
        `v1.1.9: Post 2022 update hotfixes and improvements.`,
        `Your score predictions now show in views where relevant.`,
        `Standings refresh every 8 hours if you stay on the leaderboards page.`,
        `No longer marks all games when inspecting your profile.`,
        `No longer shows common games marking help text when no games are in the inspected profile.`,
        `Fixed sorting layout on mobile.`,
        `Fixed crash when logged out and inspecting earlier rounds.`,
      ],
    },
    {
      date: '2021-12-30',
      updates: [
        `Welcome to the 2022 update! We've added and improved some things to make the next year more fun. Here's a detailed breakdown.`,
        `Your number one requested feature: We can now override scores coming from the RAWG API meaning we should never lose scores again and we can add our own if the API isn't updated yet.`,
        `New feature - Predictions! Enter your review score predictions for maximum boasting power! It doesn't grant any points to your profile as of now but it feels nice to be able to show your perfect predictions.`,
        `All previous winners of rounds now have a crown in the leaderboards and a tooltip showing how many times they've won.`,
        `Added an experimental sorting mode called "Surprise" which sorts on how popular a game was versus how good a score it got.`,
        `The previous round is now shown on home until the winner is crowned.`,
        `You can now sort by score on the games page.`,
        `Games with no real release date (as set in the RAWG API) are now filtered out.`,
        `Increased image loading performance by 53% which should make the site and some views faster to load and navigate.`,
        `It is now easier to create new profiles from the login view.`,
        `Tons of minor UI improvements.`,
        `Fixed bug causing it to be impossible to create profiles when browsing on mobile. Oops.`,
        `Fixed bug causing end of round and winner banners to be shown at the same time.`,
      ],
    },
    {
      date: '2021-12-23',
      updates: [
        `Games you have in common with a competitor are now marked when logged in`,
        `Added score badges to leaderboard`,
        `Removed Gap avg in favor of projected score`,
        `Score average now doesn't include the lowest scoring game`,
        `Minor UI tweaks`,
      ],
    },
    {
      date: '2021-11-06',
      updates: [
        `Added game filters to mobile.`,
        `Added score avg sort to leaderboards`,
        `Fixed crash if you loaded /leaderboards first`,
        `Removed our failed twitter link (use discord)`,
        `Minor UI tweaks`,
      ],
    },
    {
      date: '2021-09-12',
      updates: [
        `New! This round the lowest scoring game won't count towards your total. This should make the competition more even.`,
        `Fixed issue causing non-reviewed games not being droppable`,
      ],
    },
    {
      date: '2021-07-04',
      updates: [
        `New! Games can no longer be picked up or dropped 3 days before the release date to prevent people having access to early reviews and taking action based on more or less perfect information.`,
        `Minor polish here and there`,
      ],
    },
    {
      date: '2021-07-04',
      updates: [
        `Q3 is live.`,
        `Responsive mobile support improvements (and some degradations while we're reworking it, but we'll fix those soon).`,
        `Fixed bug preventing saving of games. Sorry about that! These quarter switches are still a bit complicated. Should work for the entire quarter now though.`,
      ],
    },
    {
      date: '2021-05-15',
      updates: [
        `Responsive mobile support first pass complete.`,
        `Responsive layout for all sizes improved especially tablet.`,
        `Lots of layout fixes.`,
        `Fixed bug preventing saving of games.`,
      ],
    },
    {
      date: '2021-04-25',
      updates: [
        `New nice layout on your games list.`,
        `Cookies and bombs now show on cards.`,
        `Linked to discord on sign up drawer.`,
        `Minor visual improvements.`,
      ],
    },
    {
      date: '2021-04-24',
      updates: [
        `Lists on home are now limited to 5 entries and the rest can be viewed in a drawer.`,
        `Added cookies and bombs to show games that surprise us in a good or bad way.`,
        `Added discord server. Talk to us directly there and give feedback!`,
        `Reviewed games are now shown as cards.`,
        `Changed appearance of hype to a fading flame.`,
        `Fixed text field clear button showing when text field is empty.`,
      ],
    },
    {
      date: '2021-04-19',
      updates: [
        `Q2 is now live and Q1 winner is announced!`,
        `Rewrote entire backend for better rounds management.`,
        `Players can now select their rounds history in their games list.`,
        `Leaderboard history can now be selected in the leaderboards tab.`,
      ],
    },
    {
      date: '2021-03-20',
      updates: [
        `Added several stats to the leaderboards tab making it easier to compare profiles.`,
        `Improved responsiveness of the site on several devices.`,
        `Several improvements to rounds code before next quarter.`,
        `Experimental mobile responsive support.`,
        `Fixed crash in safari.`,
        `Fixed issue clicking through add buttons to open game modal.`,
        `Many minor visual improvements.`,
      ],
    },
    {
      date: '2021-02-28',
      updates: [
        `New start page.`,
        `New responsive design for tablets up to desktop.`,
        `Lists and cards are now clickable and show game information.`,
        `Added more information about how to play and how the site works.`,
        `Added hype meter to games.`,
        `Added hype sorting to games.`,
        `Expanded upcoming games list.`,
        `Added best scored list.`,
        `Improved some visuals.`,
      ],
    },
    {
      date: '2021-02-21',
      updates: [
        `Invites to beta are now open to anyone with a invite code.`,
        `Added possibility to drop unreleased games from your games list.`,
        `Added password reset button.`,
        `Added policies.`,
        `Fixed bronze counting as gold.`,
        `Moved signup to drawer.`,
        `Improved validation of profile names.`,
      ],
    },
    {
      date: '2021-02-20',
      updates: [
        `Added ability to drop released games without reviews after a week.`,
        `Added score badges and beta tester title to testers.`,
        `Various visual improvements.`,
      ],
    },
    {
      date: '2021-02-18',
      updates: [
        `Fixed game info tooltips hiding scrollbar.`,
        `Added X/10 games selected for round to show you should pick 10 games before the round is over.`,
      ],
    },
    {
      date: '2021-02-16',
      updates: [
        `Added reviewed games amount to leaderboards so players can compare their progress.`,
        `Fixed games page not loading.`,
        `Fixed page not loading on iPhone and Safari on Mac.`,
        `Several optimizations and quality fixes.`,
      ],
    },
    {
      date: '2021-02-07',
      updates: [
        `Main views when logged in completely redesigned. Now with a nice top menu and profile pane.`,
        'Leaderboard positions added, showing that same point wins are shared',
        'Added current round timeline bar',
        'Lists are now cached for instant responsiveness after first load',
        'Game search is now delayed a few millis to make typing faster',
        'Added hotjar feedback button (sticky to the right side of the screen)',
        'Sped up sign up flow by removing game selection (which also makes it easier to maintain the site)',
        'Logo now links to start page',
        'Favicon added',
      ],
    },
    {
      date: '2021-01-24',
      updates: [
        'Added update log drawer',
        'Added new logo',
        'Eligible games to select are now only games in the future',
        'Optimized app loading so no loading screen is needed',
      ],
    },
    {
      date: '2021-01-22',
      updates: ['Fixed leaderboard sorting'],
    },
    {
      date: '2021-01-17',
      updates: [
        'New name "Critical Showdown". Launch Party was used by too many.',
        'New dark design',
        'New footer',
        'Created Twitter account @CritShowdown',
      ],
    },
    {
      date: '2021-01-05',
      updates: [
        "Added message and sign in link if you've been logged out due to inactivity (1 hour).",
        'Now marks current user in leaderboard. ',
        'Games marked as having no date from RAWG now display "No date" instead of the invalid date. ',
        'Minor polish.',
      ],
    },
  ]

  return (
    <ChildSpacerV>
      <HLine
        label="Updates"
        buttonLabel="View all updates"
        onButtonClick={onButtonClick}
      />
      <MaintenanceMessage />
      <EndRoundMessage />
      <WinnerMessage />
      <NextRoundMessage />

      {newsList.slice(0, limit ? limit : newsList.length).map((item, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: semanticSurface.surfaceLayerLow,
              borderRadius: '8px',
              padding: '24px',
            }}
          >
            <SubtleText text={item.date} />
            <Box pt={5} />
            {item.title && <h3>{item.title}</h3>}
            {item.text && (
              <p style={{ color: semanticMedia.contrastMedium }}>{item.text}</p>
            )}
            <ul style={{ padding: '0 16px', margin: 0 }}>
              {item.updates.map((update, index) => (
                <li style={{ paddingBottom: 8 }} key={index}>
                  <span style={{ color: semanticMedia.contrastMedium }}>
                    {update}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </ChildSpacerV>
  )
}
