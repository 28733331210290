import { Typography } from '@mui/material'
import { colors } from 'styles/colors'
import { daysLeft } from 'util/date'
import { CSTooltip } from './csTooltip'

function ContentComponent({ game }) {
  const daysLeftForPickup = daysLeft(game.released)
  return (
    <>
      <Typography>
        {/* <strong></strong> */}
        {daysLeftForPickup > 3 &&
          `Locks for pickup in ${
            daysLeftForPickup - 3
          } days. Make sure you get it before it's too late!`}
        {daysLeftForPickup <= 3 &&
          `Locked for pickup - too close to release date`}
        {/*         <br />
        <TextSqueezer>
          Games lock 3 days before release to prevent review sniping, i.e.
          checking early reviews before dropping games at the last second.
        </TextSqueezer> */}
      </Typography>
    </>
  )
}

export const GameLock = ({ game, vAlign }) => {
  const daysLeftForPickup = daysLeft(game.released)
  if (daysLeftForPickup > 10 || daysLeftForPickup <= 0) {
    return null
  }

  return (
    <CSTooltip text={<ContentComponent game={game} />}>
      <span>
        {daysLeftForPickup > 3 && (
          <span
            className="material-symbols-rounded"
            style={{
              fontSize: '18px',
              verticalAlign: vAlign ? vAlign : 'middle',
              color: colors.white,
              opacity: 0.15 + (daysLeftForPickup / 3) * 0.85,
            }}
          >
            lock_open
          </span>
        )}
        {daysLeftForPickup <= 3 && (
          <span
            className="material-symbols-rounded"
            style={{
              fontSize: '18px',
              verticalAlign: vAlign ? vAlign : 'middle',
              color: colors.white,
              opacity: 0.15 + (daysLeftForPickup / 3) * 0.85,
            }}
          >
            lock
          </span>
        )}
      </span>
    </CSTooltip>
  )
}
