import { Box, useMediaQuery } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { Cols } from 'Components/layout/cols'
import { JoinView } from 'Components/panes/joinView'
import { ReviewedList } from 'Components/panes/reviewedList'
import { UserPredictList } from 'Components/panes/userPredictList'
import { WhatsNew } from 'Components/panes/whatsNew'
import { WhatsNewList } from 'Components/panes/whatsNewList'
import { isAfter, isBefore } from 'date-fns'
import { Link } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { PicksSummary } from '../profilePanel/picksSummary'
import { ShowyInfo } from './showyInfo'
import { FriendRequests } from '../profilePanel/friendRequests'
import { TextSqueezer } from 'Components/visual/textWrapper'

function HomeHeader({ user }) {
  return (
    <>
      {' '}
      {user.isAuthenticated && <h1>Welcome back!</h1>}
      {!user.isAuthenticated && <h1>Welcome!</h1>}
      <Box p={2} />
    </>
  )
}

export const HomeContent = () => {
  const { user } = useAuth()

  const { mainRounds, selectedRound } = useRounds()
  const isMobile = useMediaQuery(query.mobile)

  if (isAfter(selectedRound.startDate, mainRounds.current.endDate)) {
    return (
      <>
        {!isMobile && (
          <>
            <HomeHeader user={user} />
            <Box pt={5} />
          </>
        )}
        <p>
          Round {selectedRound.fullName} has not started yet. In the meantime
          you can check out which games will be up for grabs in this round.
        </p>
        <div>
          <Link to="/games">View games for {selectedRound.fullName}</Link>
        </div>
        <Box p={4} />
        <UserPredictList />
      </>
    )
  }

  if (isBefore(selectedRound.endDate, mainRounds.current.startDate)) {
    return (
      <>
        {!isMobile && (
          <>
            <HomeHeader user={user} />
            <Box pt={5} />
          </>
        )}

        <p>Round {selectedRound.fullName} has already completed.</p>
        <div>
          <Link to="/leaderboards">
            View final results for {selectedRound.fullName}
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      {!isMobile && (
        <>
          <HomeHeader user={user} />
          <Box pt={8} />
        </>
      )}

      <ChildSpacerVAll>
        <Breakpoint customQuery={query.tabletDown}>
          <ChildSpacerVAll>
            {!user.isAuthenticated && <JoinView />}
            <ReviewedList />
            <Box p={5} />
            {user.isAuthenticated && (
              <>
                <FriendRequests />
                <Box p={5} />
                <h3>Picks mode</h3>
                <PicksSummary placedOnProfile={false} />
                <Box p={5} />
                <h3>Predictions mode</h3>
                <UserPredictList />
                <Box p={5} />
                <WhatsNew />
                <Box p={5} />
              </>
            )}
            <ShowyInfo />
          </ChildSpacerVAll>
        </Breakpoint>
        <Breakpoint customQuery={query.tabletUp}>
          {!user.isAuthenticated && (
            <TextSqueezer>
              <JoinView />
              <ReviewedList />
              <Box p={5} />
              <ShowyInfo />
              <Box p={5} />
            </TextSqueezer>
          )}
          {user.isAuthenticated && (
            <Cols set="2fr" gap="96px">
              <div>
                <ReviewedList />
                <Box p={5} />
                <h3>Picks mode</h3>
                <PicksSummary placedOnProfile={false} />
                <Box p={5} />
                <h3>Predictions mode</h3>
                <UserPredictList />
                <Box p={5} />
                <WhatsNewList limit={1} />
                <Box p={5} />
                <ShowyInfo />
                <Box p={5} />
              </div>
            </Cols>
          )}
        </Breakpoint>
      </ChildSpacerVAll>
    </>
  )
}
